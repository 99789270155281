export const COLUMN_DEFINITIONS = [
  {
    id: 'name',
    header: 'Name',
    cell: (item: any) => item?.friendlyName,
    sortingField: 'name',
  },
  {
    id: 'default branch',
    header: 'Default Branch',
    cell: (item: any) => item?.defaultBranch,
  },
  {
    id: 'id',
    header: 'ID',
    cell: (item: any) => item?.id,
  },
];
