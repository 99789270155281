/* eslint-disable react-hooks/rules-of-hooks */
import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
  Toggle,
} from '@cloudscape-design/components';
import { catalogAPI } from 'api';
import { usePageLayoutContext } from 'components/common/layout';
import DeleteModal from 'components/delete-modal';
import { EmptyState } from 'components/empty-state/EmptyState';
import { API_URL_PATH_LIST_INTEGRATION } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { useCatalogManagerContext } from 'pages/catalog/CatalogManager/CatalogManagerPage';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CatalogIntegrationProps } from 'types/custom';
import { getMatchesCountText } from 'utils';
import DuplicateIntegration from '../DuplicateIntegration';

const IntegrationTable = ({ type}: { type: string }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [flowType, setFlowType] = useState(type);
  const [disableAllToggles, setDisableAllToggles] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

  const onDeleteDiscard = () => setShowDeleteModal(false);
  const navigate = useNavigate();

  const {
    selectedApplication,
    setActiveMainTabId,
    integrationsRefetch,
    allIntegrations,
    integrationsLoading,
    selectedIntegration,
    setSelectedIntegration,
    applicationsRefetch,
    applicationsLoading,
  } = useCatalogManagerContext();

  const { setNotification } = usePageLayoutContext();

  const {
    status: deleteIntegrationStatus,
    fetchData: deleteIntegration,
    error: deleteIntegrationError,
    loading: deleteIntegrationLoading,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'DELETE',
      url: `${API_URL_PATH_LIST_INTEGRATION}/${selectedIntegration && selectedIntegration.length > 0 ? selectedIntegration[0].type : ""}/${selectedIntegration && selectedIntegration.length > 0 ? selectedIntegration[0] ? selectedIntegration[0].id : '' : '' }`,
    },
    { manual: true }
  );

  const handleButtonDropdownClick = (event: any) => {
    event.preventDefault();
    if (event.detail.id === 'integration-delete') {
      setShowDeleteModal(true);
    } else if (event.detail.id === 'integration-edit') {
      const editUrl = '/catalog/admin/integration/edit';
      const id = selectedIntegration![0]?.id;

      navigate(`${editUrl}/${id}`, {
        state: {
          action: 'edit',
          id,
          flowType: flowType,
        },
      });
    }else if (event.detail.id === 'integration-duplicate') {
      setIsAssignModalOpen(true);
    }
  };

  useEffect(() => {
    if (deleteIntegrationStatus === 200) {
      if(!deleteIntegrationLoading){
        applicationsRefetch();
      }
      setNotification([
        {
          type: 'success',
          content: `Deleted application ${selectedIntegration![0]?.name} successfully`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-delete-integration',
        },
      ]);

      if (allIntegrations.length > 0) {
        setSelectedIntegration([allIntegrations[1]]);
      }
    } else if (deleteIntegrationStatus > 200) {
      setNotification([
        {
          type: 'error',
          content: deleteIntegrationError,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-delete-integration-error',
        },
      ]);
    }
  }, [deleteIntegrationStatus]);

  const onDeleteConfirm = () => {
    deleteIntegration();
    setShowDeleteModal(false);
  };
  
  const {
    error: updateIntegrationError,
    fetchData: updateIntegrationStatus,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'PATCH',
      url: selectedIntegration && selectedIntegration.length > 0 && selectedIntegration[0] ? `${ API_URL_PATH_LIST_INTEGRATION}/${selectedIntegration && selectedIntegration.length > 0 ? selectedIntegration[0].type : ""}/${selectedIntegration[0].id}/toggle` : "",
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        status: selectedIntegration && selectedIntegration.length > 0 && selectedIntegration[0] ? selectedIntegration[0].status : "",
      } as Partial<CatalogIntegrationProps>,
    },
    { manual: true }
  );

  const handleIntegrationToggle = async (integration: CatalogIntegrationProps, checked: boolean) => {
    integration.status = checked;
    setDisableAllToggles(true);
    const response = await updateIntegrationStatus();
    if (response && response.status >= 200 && response.status < 300) {
      setNotification([{
          type: 'success',
          content: `Updated integration ${integration.name} status successfully`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-update-status-integration',
      }]);
    } else {
      integration.status = !checked;
      setNotification([
        {
          type: 'error',
          content: updateIntegrationError,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-update-status-integration-error',
        },
      ]);
    }
    setDisableAllToggles(false);
  }

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allIntegrations || [],
    {
      filtering: {
        empty: (
          <EmptyState
            title="No integrations"
            subtitle="No integration to display."
            action={
              <Button
                onClick={() => {
                  flowType === 'manager'
                    ? navigate('/catalog/admin/integration/create', {
                      state: {
                        action: 'create',
                        applicationId: selectedApplication && selectedApplication?.length > 0 ? selectedApplication[0].id : '',
                      },
                    })
                    : setActiveMainTabId('shopping');
                }}
              >
                Create a Integration
              </Button>
            }
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: 10 },
      sorting: {},
    }
  );


  return (
    <>
      <Table
        {...collectionProps}
        onSelectionChange={({ detail }) => {
          setSelectedIntegration(detail.selectedItems);
        }}
        selectedItems={selectedIntegration}
        loading={integrationsLoading || deleteIntegrationLoading || applicationsLoading}
        wrapLines={true}
        stripedRows={true}
        resizableColumns={true}
        columnDefinitions={[
          {
            id: 'status',
            header: 'Enabled',
            cell: (item: CatalogIntegrationProps) => (<Toggle
              checked={item.status === true}
              onChange={({ detail }) => handleIntegrationToggle(item, detail.checked)}
              disabled={disableAllToggles} />),
          },
          {
            id: 'name',
            header: 'Name',
            cell: (item: any) => item?.name,
            sortingField: 'name',
          },
          {
            id: 'type',
            header: 'Type',
            cell: (item: any) => item?.type,
            sortingField: 'type',
          },
         {
            id: 'state_code',
            header: 'State Code',
            cell: (item: any) => item?.stateCode,
            sortingField: 'type',
          },
        ]}
        items={items}
        loadingText="Loading integrations"
        selectionType="single"
        variant="embedded"
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Find Integration"
          />
        }
        header={
          <Header
            counter={
              '(' + `${allIntegrations.length > 0 ? selectedIntegration && selectedIntegration.length : 0}` + `/${allIntegrations && allIntegrations.length})`
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={() => integrationsRefetch()} iconName="refresh" loading={integrationsLoading} />
                <Button
                  onClick={() => {
                    flowType === 'manager'
                      ? navigate('/catalog/admin/integration/create', {
                        state: {
                          action: 'create',
                          flowType: flowType,
                          applicationId: selectedApplication && selectedApplication.length > 0 ? selectedApplication[0].id : '',
                        },
                      })
                      : setActiveMainTabId('shopping');
                  }}
                >
                  Create
                </Button>
                <ButtonDropdown
                  items={[
                    {
                      text: 'Edit',
                      id: 'integration-edit',
                      disabled: allIntegrations?.length === 0,
                    },
                    {
                      text: 'Duplicate',
                      id: 'integration-duplicate',
                      disabled: allIntegrations?.length === 0,
                    },
                    {
                      text: 'Delete',
                      id: 'integration-delete',
                      disabled: allIntegrations?.length === 0,
                    },
                  ]}
                  onItemClick={handleButtonDropdownClick}
                >
                  Actions
                </ButtonDropdown>
              </SpaceBetween>
            }
          >
            List of Integrations
          </Header>
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
      />

      {selectedIntegration && selectedIntegration.length > 0 && (
        <DeleteModal
          visible={showDeleteModal}
          onDiscard={onDeleteDiscard}
          onDelete={onDeleteConfirm}
          itemName={selectedIntegration![0]?.name}
          itemCount={selectedIntegration?.length}
          moduleName="Integration"
        />
      )}
      {selectedIntegration?.length && (
        <>
          <DuplicateIntegration
            isOpen={isAssignModalOpen}
            onClose={() => setIsAssignModalOpen(false)}
            selectedIntegration={selectedIntegration[0]}
          />
        </>
      )}
    </>
  );
};

export default IntegrationTable;
