import { useEffect, useState } from 'react';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Table,
  TextFilter,
  Header,
  SpaceBetween,
  ButtonDropdown,
  Pagination,
} from '@cloudscape-design/components';
import { EmptyState } from 'components/empty-state/EmptyState';

import useFetch from 'hooks/useFetch';
import { usePhysicalDevicesContext } from 'pages/physical-device-manager/ListPhysicalDevicesPage';
import { usePageLayoutContext } from 'components/common/layout';
import { COLUMN_DEFINITIONS } from './table-config';
import { API_URL_PATH_PHYSICAL_DEVICE } from 'constants/urls';
import DeleteModal from 'components/delete-modal';
import { getMatchesCountText } from 'utils';
import { platformCoreAPI } from 'api';

const PhysicalDevicesTable = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onDeleteDiscard = () => setShowDeleteModal(false);

  const navigate = useNavigate();

  const { allItems, loading, error, setSelectedItem, selectedItem, refetch } = usePhysicalDevicesContext();

  const { setNotification } = usePageLayoutContext();

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: (
          <EmptyState
            title="No instances"
            subtitle="No instances to display."
            action={
              <Button
                onClick={() =>
                  navigate('/lns/create', {
                    state: {
                      action: 'create',
                    },
                  })
                }
              >
                Create an LNS
              </Button>
            }
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: 10 },
      sorting: {},
      // selection: {
      //     keepSelection: true,
      //     trackBy: 'name',
      //     defaultSelectedItems: [allItems.length && allItems[0]],
      // },
    }
  );

  // const { selectedItems } = collectionProps;

  useEffect(() => {
    if (!selectedItem![0]?.name) {
      setSelectedItem([allItems.length > 0 && allItems[0]?.name ? allItems[0] : []]);
    }
  }, [allItems]);

  // const [sortingDescending, setSortingDescending] = useState(false);
  // const [sortingColumn, setSortingColumn] = useState(COLUMN_DEFINITIONS[0]);

  // const handleSortingChange = (event: any) => {
  //     setSortingDescending(event.detail.isDescending);
  //     setSortingColumn(event.detail.sortingColumn);
  // };

  const { status, fetchData: deletePhysicalDevice } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'DELETE',
      url: `${API_URL_PATH_PHYSICAL_DEVICE}/${selectedItem?.length ? selectedItem![0]?.name : ''}`,
    },
    { manual: true }
  );

  // useEffect(() => {
  //     if (status === 200) {
  //         refetch();
  //         setNotification([
  //             {
  //                 type: 'success',
  //                 content: `Deleted physical device ${
  //                     selectedItem![0]?.name
  //                 } successfully`,
  //                 dismissible: true,
  //                 dismissLabel: 'Dismiss message',
  //                 onDismiss: () => setNotification([]),
  //                 id: 'flash-delete-physical-device',
  //             },
  //         ]);

  //         setSelectedItem([
  //             allItems.length > 0 && allItems[0]?.name ? allItems[1] : [],
  //         ]);
  //     }
  // }, [status]);

  const handleButtonDropdownClick = (event: any) => {
    event.preventDefault();
    // if (event.detail.id === 'delete-lns') {
    //     deletePhysicalDevice()
    //         .then(() => {
    //             refetch();
    //             setNotification([
    //                 {
    //                     type: 'success',
    //                     content: `Deleted physical device ${
    //                         selectedItem![0]?.name
    //                     } successfully`,
    //                     dismissible: true,
    //                     dismissLabel: 'Dismiss message',
    //                     onDismiss: () => setNotification([]),
    //                     id: 'flash-delete-physical-device',
    //                 },
    //             ]);
    //             setSelectedItem([]);
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //         });
    // }
    if (event.detail.id === 'delete-lns') {
      setShowDeleteModal(true);
    } else if (event.detail.id === 'edit-lns') {
      navigate(`/lns/edit/${selectedItem![0].name}`, {
        state: {
          action: 'edit',
          id: selectedItem![0].name,
        },
      });
    }
  };

  const onDeleteConfirm = () => {
    deletePhysicalDevice()
      .then(() => {
        refetch();
        setNotification([
          {
            type: 'success',
            content: `Deleted physical device ${selectedItem![0]?.name} successfully`,
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setNotification([]),
            id: 'flash-delete-physical-device',
          },
        ]);
        setSelectedItem([]);
        setShowDeleteModal(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <Table
        {...collectionProps}
        onSelectionChange={({ detail }) => setSelectedItem(detail.selectedItems)}
        selectedItems={selectedItem}
        loading={loading}
        wrapLines={true}
        stripedRows={true}
        resizableColumns={true}
        columnDefinitions={COLUMN_DEFINITIONS}
        items={items}
        // onSortingChange={handleSortingChange}
        // sortingColumn={sortingColumn}
        // sortingDescending={sortingDescending}
        loadingText="Loading resources"
        selectionType="single"
        trackBy="name"
        className="table-container"
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Find resources"
          />
        }
        header={
          <Header
            counter={
              selectedItem?.length && selectedItem[0]?.name
                ? '(' + selectedItem.length + `/${allItems && allItems.length})`
                : `(${allItems && allItems.length})`
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  onClick={() =>
                    navigate('/lns/create', {
                      state: {
                        action: 'create',
                      },
                    })
                  }
                >
                  Create
                </Button>
                <ButtonDropdown
                  items={[
                    {
                      text: 'Edit',
                      id: 'edit-lns',
                      disabled: allItems?.length === 0,
                    },
                    {
                      text: 'Delete',
                      id: 'delete-lns',
                      disabled: allItems?.length === 0,
                    },
                  ]}
                  onItemClick={handleButtonDropdownClick}
                >
                  Actions
                </ButtonDropdown>
              </SpaceBetween>
            }
          >
            List of physical devices
          </Header>
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
      />

      <DeleteModal
        visible={showDeleteModal}
        onDiscard={onDeleteDiscard}
        onDelete={onDeleteConfirm}
        itemName={selectedItem![0]?.name}
        itemCount={selectedItem?.length}
        moduleName="Physical Device"
      />
    </>
  );
};

export default PhysicalDevicesTable;
