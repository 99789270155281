import { ColumnLayout, Box, SpaceBetween } from '@cloudscape-design/components';
import { usePhysicalDevicesContext } from 'pages/physical-device-manager/ListPhysicalDevicesPage';

const PhysicalDevicesDetails = () => {
  const { selectedItem } = usePhysicalDevicesContext();
  return (
    <ColumnLayout columns={2} variant="text-grid">
      <SpaceBetween size="l">
        <div>
          <Box variant="awsui-key-label">Friendly Name</Box>
          <div>{selectedItem![0]?.attributes?.friendlyName}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Description</Box>
          <div>{selectedItem![0]?.description}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Relation</Box>
          <div>{selectedItem![0]?.relation}</div>
        </div>
      </SpaceBetween>
      <SpaceBetween size="l">
        <div>
          <Box variant="awsui-key-label">Group Path</Box>
          <div>{selectedItem![0]?.groupPath}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Parent Path</Box>
          <div>{selectedItem![0]?.parentPath}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Host</Box>
          <div>{selectedItem![0]?.attributes?.host}</div>
        </div>
      </SpaceBetween>
    </ColumnLayout>
  );
};

export default PhysicalDevicesDetails;
