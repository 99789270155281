import { Container } from '@cloudscape-design/components';
import CreateDevice from 'components/device-manager/CreateDevice';

const CreateDevicePage = () => {
  return (
    <Container className="iot-container">
      <CreateDevice />
    </Container>
  );
};

export default CreateDevicePage;
