import { Box, Button, ColumnLayout, Modal, SpaceBetween } from '@cloudscape-design/components';
import React from 'react';

const MqttClientDetailsModal = ({
  setShowMqttClientModal,
  selectedClient,
}: {
  setShowMqttClientModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedClient: any;
}) => {
  return (
    <Modal
      onDismiss={() => setShowMqttClientModal(false)}
      visible={true}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => setShowMqttClientModal(false)}>
              Cancel
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Details"
    >
      <ColumnLayout columns={2} variant="text-grid">
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Description</Box>
            <div>{selectedClient![0]?.description}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Converter Friendly Name</Box>
            <div>{selectedClient![0]?.attributes?.converterFriendlyName}</div>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Tektelic Integration Id</Box>
            <div>{selectedClient![0]?.attributes?.tektelicIntegrationId}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Device Group Friendly Name</Box>
            <div>{selectedClient![0]?.attributes.deviceGroupFriendlyName}</div>
          </div>
        </SpaceBetween>
      </ColumnLayout>
    </Modal>
  );
};

export default MqttClientDetailsModal;
