import { useEffect, useState } from 'react';
import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
  Icon,
} from '@cloudscape-design/components';
import { catalogAPI } from 'api';
import { usePageLayoutContext } from 'components/common/layout';
import DeleteModal from 'components/delete-modal';
import { EmptyState } from 'components/empty-state/EmptyState';
import { API_URL_PATH_LIST_ACCOUNTS } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { useCatalogManagerContext } from 'pages/catalog/CatalogManager/CatalogManagerPage';
import { getMatchesCountText } from 'utils';
import RegisterModal from '../RegisterAccount/RegisterModal';
import { COLUMN_DEFINITIONS } from './table.config';

const AwsAccounts = () => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showRegisterModal, setShowRegisterModal] = useState<boolean>(false);
  const [accountId, setAccountId] = useState<string>('');

  const onDeleteDiscard = () => setShowDeleteModal(false);
  const onRegisterDiscard = () => {
    setShowRegisterModal(false);
    setAccountId('');
  };

  const { allAccounts, accountsError, accountsLoading, accountsRefetch, selectedAccount, setSelectedAccount } =
    useCatalogManagerContext();

  const { setNotification } = usePageLayoutContext();

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allAccounts || [],
    {
      filtering: {
        empty: (
          <EmptyState
            title="No accounts"
            subtitle="No accounts to display."
            action={<Button onClick={() => setShowRegisterModal(true)}>Register an Account</Button>}
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: 10 },
      sorting: {},
    }
  );

  useEffect(() => {
    if ((!selectedAccount || !selectedAccount![0]?.accountId) && allAccounts) {
      if (allAccounts.length > 0 && allAccounts[0]?.accountId) {
        setSelectedAccount([allAccounts[0]]);
      }
    }
  }, [allAccounts]);

  const {
    status: deleteAccountStatus,
    fetchData: deleteAccount,
    error: deleteAccountError,
    loading: deleteAccountLoading,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'DELETE',
      url: API_URL_PATH_LIST_ACCOUNTS,
      param: selectedAccount?.length ? selectedAccount![0]?.accountId : '',
    },
    { manual: true }
  );

  const {
    status: registerAccountStatus,
    fetchData: registerAccount,
    error: registerAccountError,
    response: registerAccountResponse,
    loading: registerAccountLoading,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'POST',
      url: API_URL_PATH_LIST_ACCOUNTS,
      data: {
        accountId,
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (deleteAccountStatus === 200) {
      accountsRefetch();
      setNotification([
        {
          type: 'success',
          content: `Deleted account ${selectedAccount![0]?.accountId} successfully`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-delete-account',
        },
      ]);

      if (allAccounts.length > 0 && allAccounts[0]?.accountId) {
        setSelectedAccount([allAccounts[1]]);
      }
    } else if (deleteAccountStatus > 200) {
      setNotification([
        {
          type: 'error',
          content: deleteAccountError,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-delete-account-error',
        },
      ]);
    }
  }, [deleteAccountError, deleteAccountStatus]);

  useEffect(() => {
    if (registerAccountStatus === 201) {
      setShowRegisterModal(false);
      setAccountId('');
      accountsRefetch();
      setNotification([
        {
          type: 'success',
          content: `Registered account ${registerAccountResponse?.accountId} successfully`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-register-account',
        },
      ]);

      if (allAccounts.length > 0 && allAccounts[0]?.accountId) {
        setSelectedAccount([allAccounts[1]]);
      }
    }
  }, [registerAccountStatus, registerAccountResponse]);

  const handleButtonDropdownClick = (event: any) => {
    event.preventDefault();
    if (event.detail.id === 'account-delete') {
      setShowDeleteModal(true);
    }
  };

  const onDeleteConfirm = () => {
    deleteAccount();

    setShowDeleteModal(false);
  };

  return (
    <>
      <Table
        {...collectionProps}
        onSelectionChange={({ detail }) => setSelectedAccount(detail.selectedItems)}
        selectedItems={selectedAccount}
        loading={accountsLoading || deleteAccountLoading || registerAccountLoading}
        wrapLines={true}
        stripedRows={true}
        resizableColumns={true}
        columnDefinitions={COLUMN_DEFINITIONS}
        items={items}
        loadingText="Loading accounts"
        selectionType="single"
        // trackBy='name'
        className="table-container"
        variant="embedded"
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Find accounts"
          />
        }
        header={
          <Header
            counter={
              selectedAccount?.length && selectedAccount[0]?.accountId
                ? '(' + selectedAccount.length + `/${allAccounts && allAccounts.length})`
                : `(${allAccounts && allAccounts.length})`
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={accountsRefetch} iconName='refresh' loading={accountsLoading} />
                <Button onClick={() => setShowRegisterModal(true)}>Register</Button>
                <ButtonDropdown
                  items={[
                    {
                      text: 'Delete',
                      id: 'account-delete',
                      disabled: allAccounts?.length === 0,
                    },
                  ]}
                  onItemClick={handleButtonDropdownClick}
                >
                  Actions
                </ButtonDropdown>
              </SpaceBetween>
            }
          >
            List of AWS Accounts
          </Header>
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
      />

      {selectedAccount && selectedAccount.length > 0 && (
        <DeleteModal
          visible={showDeleteModal}
          onDiscard={onDeleteDiscard}
          onDelete={onDeleteConfirm}
          itemName={selectedAccount![0]?.accountId}
          itemCount={selectedAccount?.length}
          moduleName="Account"
        />
      )}

      {showRegisterModal && (
        <RegisterModal
          visible={showRegisterModal}
          onDiscard={onRegisterDiscard}
          setAccountId={setAccountId}
          registerAccount={registerAccount}
          accountId={accountId}
          error={registerAccountError}
        />
      )}
    </>
  );
};

export default AwsAccounts;
