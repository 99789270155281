/* eslint-disable react-hooks/rules-of-hooks */
import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from '@cloudscape-design/components';
import { catalogAPI } from 'api';
import { usePageLayoutContext } from 'components/common/layout';
import DeleteModal from 'components/delete-modal';
import { EmptyState } from 'components/empty-state/EmptyState';
import { API_URL_PATH_LIST_DEPLOYMENTS, API_URL_PATH_LIST_SHOPPING_DEPLOYMENTS } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { useCatalogManagerContext } from 'pages/catalog/CatalogManager/CatalogManagerPage';
import { useCatalogShoppingContext } from 'pages/catalog/CatalogShopping/CatalogShoppingPage';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMatchesCountText } from 'utils';
import { COLUMN_DEFINITIONS } from './table.config';

const DeploymentsTable = ({ type }: { type: string }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [flowType, setFlowType] = useState(type);

  const onDeleteDiscard = () => setShowDeleteModal(false);
  const navigate = useNavigate();

  const context = type === 'manager' ? useCatalogManagerContext() : useCatalogShoppingContext();

  const { allDeployments, deploymentsLoading, deploymentsRefetch, selectedDeployment, setSelectedDeployment, setActiveMainTabId } =
    context;

  const { setNotification } = usePageLayoutContext();

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allDeployments || [],
    {
      filtering: {
        empty: (
          <EmptyState
            title="No deployments"
            subtitle="No deployments to display."
            action={
              <Button
                onClick={() => {
                  flowType === 'manager'
                    ? navigate('/catalog/admin/deployments/create', {
                      state: {
                        action: 'create',
                      },
                    })
                    : setActiveMainTabId('shopping');
                }}
              >
                Create a Deployment
              </Button>
            }
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: 10 },
      sorting: {},
    }
  );

  useEffect(() => {
    if (allDeployments) {
      if (allDeployments.length > 0) {
        setSelectedDeployment([allDeployments[0]]);
      }
    }
  }, [allDeployments]);

  const {
    status: deleteApplicationStatus,
    fetchData: deleteApplication,
    error: deleteApplicationError,
    loading: deleteApplicationLoading,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'DELETE',
      url: `${flowType === 'manager' ? API_URL_PATH_LIST_DEPLOYMENTS : API_URL_PATH_LIST_SHOPPING_DEPLOYMENTS}/${selectedDeployment?.length ? selectedDeployment![0]?.id : ''
        }`,
    },
    { manual: true }
  );

  useEffect(() => {
    if (deleteApplicationStatus === 200) {
      deploymentsRefetch();
      setNotification([
        {
          type: 'success',
          content: `Deleted application ${selectedDeployment![0]?.friendlyName} successfully`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-delete-iot-application',
        },
      ]);

      if (allDeployments.length > 0 && allDeployments[0]?.id) {
        setSelectedDeployment([allDeployments[1]]);
      }
    } else if (deleteApplicationStatus > 200) {
      setNotification([
        {
          type: 'error',
          content: deleteApplicationError,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-delete-iot-app-error',
        },
      ]);
    }
  }, [deleteApplicationStatus]);

  const handleButtonDropdownClick = (event: any) => {
    event.preventDefault();
    if (event.detail.id === 'app-delete') {
      setShowDeleteModal(true);
    } else if (event.detail.id === 'app-edit') {
      const editUrl = flowType === 'manager' ? '/catalog/admin/deployment/edit' : '/catalog/user/deployment/edit';
      const id = selectedDeployment![0]?.id || selectedDeployment![0]?.id;

      navigate(`${editUrl}/${id}`, {
        state: {
          action: 'edit',
          id,
          flowType: flowType,
        },
      });
    }
  };

  const onDeleteConfirm = () => {
    deleteApplication();
    setShowDeleteModal(false);
  };

  return (
    <>
      <Table
        {...collectionProps}
        onSelectionChange={({ detail }) => {
          setSelectedDeployment(detail.selectedItems);
        }}
        selectedItems={selectedDeployment}
        loading={deploymentsLoading || deleteApplicationLoading}
        wrapLines={true}
        stripedRows={true}
        resizableColumns={true}
        columnDefinitions={COLUMN_DEFINITIONS}
        items={items}
        loadingText="Loading deployments"
        selectionType="single"
        variant="embedded"
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Find deployments"
          />
        }
        header={
          <Header
            counter={
              selectedDeployment && selectedDeployment[0]?.id
                ? '(' + selectedDeployment.length + `/${allDeployments && allDeployments.length})`
                : `(${allDeployments && allDeployments.length})`
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={deploymentsRefetch} iconName="refresh" loading={deploymentsLoading} />
                <Button
                  onClick={() => {
                    flowType === 'manager'
                      ? navigate('/catalog/admin/deployment/create', {
                        state: {
                          action: 'create',
                          flowType: flowType,
                        },
                      })
                      : setActiveMainTabId('shopping');
                  }}
                >
                  Create
                </Button>
                <ButtonDropdown
                  items={[
                    {
                      text: 'Edit',
                      id: 'app-edit',
                      disabled: allDeployments?.length === 0,
                    },
                    {
                      text: 'Delete',
                      id: 'app-delete',
                      disabled: allDeployments?.length === 0,
                    },
                  ]}
                  onItemClick={handleButtonDropdownClick}
                >
                  Actions
                </ButtonDropdown>
              </SpaceBetween>
            }
          >
            List of Deployments
          </Header>
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
      />

      {selectedDeployment && selectedDeployment.length > 0 && (
        <DeleteModal
          visible={showDeleteModal}
          onDiscard={onDeleteDiscard}
          onDelete={onDeleteConfirm}
          itemName={selectedDeployment![0]?.id!}
          itemCount={selectedDeployment?.length}
          moduleName="Deployment"
        />
      )}
    </>
  );
};

export default DeploymentsTable;
