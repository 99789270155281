import React, { useEffect, useState } from 'react';
import { Box, Button, Form, FormField, Input, Modal, Select, SpaceBetween } from '@cloudscape-design/components';
import useFetch from 'hooks/useFetch';
import { URL_PATH_DEVICE_TYPE } from 'constants/urls';
import { DeviceTypesListProps } from 'types/custom';
import { platformCoreAPI } from 'api';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';

const PublishDeviceTypeModal = ({
  deviceType,
  deviceTypeRefetch,
  setShowPublishDeviceModal,
}: {
  deviceType: DeviceTypesListProps[] | undefined;
  deviceTypeRefetch: () => void;
  setShowPublishDeviceModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  // const [visible, setVisible] = useState<boolean>(true);
  const [decoder, setDecoder] = useState<string>('');
  const [normalizer, setNormalizer] = useState<string>('');
  const [isReady, setIsReady] = useState<any>();
  const [purpose, setPurpose] = useState('');
  const [physicalDeviceType, setPhysicalDeviceType] = useState('');
  const [vendorSpecs, setVendorSpecs] = useState<OptionDefinition | null>(null);
  const [protocol, setProtocol] = useState<OptionDefinition | null>(null);
  const [disableFormFields, setDisableFormFields] = useState<boolean>(false);

  const protocolOptions = [
    { label: 'MQTT', value: 'MQTT' },
    { label: 'LoRaWAN', value: 'LoRaWAN' },
    { label: 'CoAP', value: 'CoAP' },
    { label: 'NB-IoT', value: 'NB-IoT' },
    { label: 'Zigbee', value: 'Zigbee' },
    { label: 'BLE', value: 'BLE' },
    { label: 'Bluetooth', value: 'Bluetooth' },
    { label: 'CAN', value: 'CAN' },
    { label: 'Modbus', value: 'Modbus' },
    { label: 'Modbus-TCP', value: 'Modbus-TCP' },
    { label: 'Serial', value: 'Serial' },
    { label: 'Ethernet', value: 'Ethernet' },
    { label: 'OPC-UA', value: 'OPC-UA' },
  ];

  const {
    error: publishDeviceTypeError,
    loading: publishDeviceTypeLoading,
    status: publishDeviceTypeStatus,
    fetchData: publishDeviceType,
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'PUT',
      url: URL_PATH_DEVICE_TYPE,
      params: [deviceType![0]?.name, 'publish'],
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        decoder,
        normalizer,
        purpose,
        physicalDeviceType,
        venderSpecs: {
          deviceClass: vendorSpecs?.value,
        },
        protocol: protocol?.value,
      },
    },
    { manual: true }
  );

  // useEffect(() => {
  //     if (publishDeviceTypeStatus === 200) {
  //         deviceTypeRefetch();
  //         setVisible(false);
  //     } else {
  //         setDisableFormFields(false);
  //     }
  // }, [publishDeviceTypeStatus]);

  const handlePublishDeviceType = () => {
    setDisableFormFields(true);
    publishDeviceType()
      .then(() => {
        deviceTypeRefetch();
        setShowPublishDeviceModal(false);
      })
      .catch(() => setDisableFormFields(false));
  };

  return (
    <Modal
      onDismiss={() => setShowPublishDeviceModal(false)}
      visible={true}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => setShowPublishDeviceModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handlePublishDeviceType} loading={publishDeviceTypeLoading}>
              Publish
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Publish device type"
    >
      <form onSubmit={handlePublishDeviceType}>
        <Form errorText={publishDeviceTypeError}>
          <SpaceBetween direction="vertical" size="l">
            <FormField
              label={
                <span>
                  Decoder <i>- optional</i>{' '}
                </span>
              }
            >
              <Input
                disabled={disableFormFields}
                value={decoder}
                onChange={(event) => setDecoder(event.detail.value)}
              />
            </FormField>
            <FormField label="Normalizer">
              <Input
                disabled={disableFormFields}
                value={normalizer}
                onChange={(event) => setNormalizer(event.detail.value)}
              />
            </FormField>
            <FormField
              label={
                <span>
                  Purpose <i>- optional</i>{' '}
                </span>
              }
            >
              <Input
                disabled={disableFormFields}
                value={purpose}
                onChange={(event) => setPurpose(event.detail.value)}
              />
            </FormField>
            <FormField label="Physical DeviceType">
              <Input
                disabled={disableFormFields}
                value={physicalDeviceType}
                onChange={(event) => setPhysicalDeviceType(event.detail.value)}
              />
            </FormField>
            <FormField label="Vendor Specs">
              <Select
                disabled={disableFormFields}
                selectedOption={vendorSpecs}
                onChange={({ detail }) => setVendorSpecs(detail.selectedOption)}
                options={[
                  { label: 'CLASS_A', value: 'CLASS_A' },
                  { label: 'CLASS_B', value: 'CLASS_B' },
                  { label: 'CLASS_C', value: 'CLASS_C' },
                ]}
                placeholder="Choose an option"
              />
            </FormField>
            <FormField label="Protocol">
              <Select
                disabled={disableFormFields}
                selectedOption={protocol}
                onChange={({ detail }) => setProtocol(detail.selectedOption)}
                options={protocolOptions}
                placeholder="Choose an option"
              />
            </FormField>
            {publishDeviceTypeError && (
              <p>
                <small>{publishDeviceTypeError}</small>
              </p>
            )}
          </SpaceBetween>
        </Form>
      </form>
    </Modal>
  );
};

export default PublishDeviceTypeModal;
