/* eslint-disable react-hooks/rules-of-hooks */
import { SpaceBetween, Box, ColumnLayout } from '@cloudscape-design/components';
import { useCatalogManagerContext } from 'pages/catalog/CatalogManager/CatalogManagerPage';
import { useCatalogShoppingContext } from 'pages/catalog/CatalogShopping/CatalogShoppingPage';

const DeploymentDetails = ({ type }: { type: string }) => {
  const context = type === 'manager' ? useCatalogManagerContext() : useCatalogShoppingContext();

  const { selectedDeployment } = context;

  return (
    <ColumnLayout columns={3} variant="text-grid">
      {selectedDeployment && (
        <>
          <SpaceBetween size="l">
            <div>
              <Box variant="awsui-key-label">Name</Box>
              <div>{selectedDeployment![0]?.name}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Description</Box>
              <div>{selectedDeployment![0]?.description}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Region</Box>
              <div>{selectedDeployment![0]?.region}</div>
            </div>
          </SpaceBetween>
          <SpaceBetween size="l">
            <div>
              <Box variant="awsui-key-label">Template</Box>
              <div>{selectedDeployment![0]?.templateName}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">AWS Account ID</Box>
              <div>{selectedDeployment![0]?.accountId}</div>
            </div>
          </SpaceBetween>
          <SpaceBetween size="l">
            <div>
              <Box variant="awsui-key-label">State</Box>
              <div>{selectedDeployment![0]?.state}</div>
            </div>
          </SpaceBetween>
        </>
      )}
    </ColumnLayout>
  );
};

export default DeploymentDetails;
