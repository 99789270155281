import '@cloudscape-design/global-styles/index.css';
import { Amplify } from 'aws-amplify';
import { useEffect } from 'react';

import RoutesManager from 'setup/routes-manager';

Amplify.configure({
  Auth: {
    userPoolId: window['runtimeConfig'].cognitoUserPoolId,
    userPoolWebClientId: window['runtimeConfig'].cognitoClientId,
    region: window['runtimeConfig'].cognitoRegion,
  },
  oauth: {
    domain: window['runtimeConfig'].cognitoOAuthDomain,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: window['runtimeConfig'].loginRedirectUrls,
    redirectSignOut: window['runtimeConfig'].logoutRedirectUrls,
    responseType: 'code',
  },
});

function App() {
  useEffect(() => {
    document.title += ' ' + window['runtimeConfig'].envTitle;
  }, []);
  return <RoutesManager />;
}

export default App;
