import ReactDOM from 'react-dom/client';

import './fonts/bi-sans-next/BISansNEXT-Regular.otf';
import './index.css';
import './runtimeConfig';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'setup/app-context-manager/AuthProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </QueryClientProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
