import { Alert, Box, Button, FormField, Modal, Select, SelectProps, SpaceBetween, Spinner } from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { useEffect, useMemo, useState } from 'react';
import { CatalogApplicationProps, CatalogIntegrationProps } from 'types/custom';
import { API_URL_PATH_LIST_APPLICATIONS } from 'constants/urls';
import { catalogAPI } from 'api';
import useFetch from 'hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { useCatalogManagerContext } from 'pages/catalog/CatalogManager/CatalogManagerPage';

type AssignModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedIntegration: CatalogIntegrationProps;
};

function DuplicateIntegration({ isOpen, onClose, selectedIntegration }: AssignModalProps) {
  const [selectedApplication, setSelectedApplication] = useState<OptionDefinition | null>(null);
  const navigate = useNavigate();

  const {selectedApplication : initialApplciation} = useCatalogManagerContext();

  useEffect(() => {
    setSelectedApplication(
      selectedIntegration.applicationId
        ? {
            label: initialApplciation && initialApplciation?.length > 0 ? initialApplciation[0].name : '',
            value: initialApplciation && initialApplciation?.length > 0 ? initialApplciation[0].id : '',
          }
        : null
    );
  }, [isOpen]);

  const {
    response: application,
    loading: applicationLoading,
    error: applicationError,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_APPLICATIONS,
    },
    { manual: false }
  );

  const allApplications: SelectProps.Options = useMemo(
    () => [
      ...(application?.items?.map((item: CatalogApplicationProps) => ({
        label: item.name,
        labelTag: item.id,
        value: item.id,
        description: item.description,
      })) || []),
    ],
    [application]
  );

  return (
    <Modal
      onDismiss={onClose}
      visible={isOpen}
      closeAriaLabel="Close modal"
      header={'Duplicate Integration'}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                onClose();
                const editUrl = '/catalog/admin/integration/duplicate';
                const id = selectedIntegration.id;
                navigate(`${editUrl}/${id}`, {
                  state: {
                    action: 'duplicate',
                    id,
                    applicationId: selectedApplication?.labelTag,
                    flowType: 'manager',
                  },
                });
              }}
            >
              Next
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
    <FormField label="Target Application">
      <Select
        empty="No applications found"
        placeholder="Select an application"
        selectedOption={selectedApplication}
        disabled={applicationLoading}
        loadingText="Loading applications"
        errorText="Failed getting applications"
        onChange={({ detail }) => setSelectedApplication(detail.selectedOption)}
        options={allApplications}
      />
    </FormField>
      {applicationError && (
        <Alert type="error" header="Error">
          {applicationError}
        </Alert>
      )}
    </Modal>
  );
}

export default DuplicateIntegration;
