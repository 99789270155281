import {
  SpaceBetween,
  Button,
  Spinner,
  FormField,
  Input,
  Select,
  ColumnLayout,
  Form,
} from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { catalogAPI } from 'api';
import { REGION_OPTIONS } from 'components/catalog-manager/Common';
import { usePageLayoutContext } from 'components/common/layout';
import { API_URL_PATH_LIST_SHOPPING_DEPLOYMENTS } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { useCatalogShoppingContext } from 'pages/catalog/CatalogShopping/CatalogShoppingPage';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { NextButton } from '..';

const CreateApplicationShop = () => {
  const { selectedTemplate, setStepName, setActiveMainTabId, deploymentsRefetch } = useCatalogShoppingContext();
  const [name, setName] = useState('');
  const [friendlyName, setFriendlyName] = useState('');
  const [description, setDescription] = useState('');
  const [region, setRegion] = useState<OptionDefinition | null>(null);
  const [parameters, setParameters] = useState<any>({});
  const [snowParentsCi, setSnowParentsCi] = useState('');
  const [disableFormFields, setDisableFormFields] = useState(false);

  const [isEditFlow, setIsEditFlow] = useState(false);
  const [requiredParameters, setRequiredParameters] = useState<string[]>([]);

  const location = useLocation();

  let params = useParams();

  const { setBreadcrumb } = usePageLayoutContext();

  useEffect(() => {
    let breadcrumbItems = [];

    if (
      (location && location.state?.action === 'edit') ||
      (location.pathname && location.pathname.indexOf('edit') > -1)
    ) {
      breadcrumbItems.push({
        text: 'Edit',
        href: '',
      });

      setIsEditFlow(true);
    } else {
      breadcrumbItems.push({
        text: 'Create',
        href: '/catalog/admin/application/create',
      });
    }

    setBreadcrumb([
      {
        text: 'Applications',
        href: '/catalog/admin/manager',
      },
      ...breadcrumbItems,
    ]);
  }, []);

  useEffect(() => {
    setRequiredParameters(selectedTemplate![0].requiredParameters);
  }, []);

  const {
    loading: applicationLoading,
    error: applicationError,
    fetchData: getApplication,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: `${API_URL_PATH_LIST_SHOPPING_DEPLOYMENTS}/${params?.iotApplicationId}`,
    },
    { manual: true }
  );

  const { error, loading, status, fetchData } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'POST',
      url: API_URL_PATH_LIST_SHOPPING_DEPLOYMENTS,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        id: selectedTemplate![0].id,
        friendlyName,
        name,
        description,
        region: region?.value,
        parameters,
        snowParentsCi,
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (status === 201) {
      let stateObj = {};

      if (isEditFlow) {
        stateObj = {
          action: 'update-application',
          status: 'success',
          message: `Successfully updated application ${name}`,
          tab: 'applications',
        };
      } else {
        stateObj = {
          action: 'create-application',
          status: 'success',
          message: `Successfully created application ${name}`,
          tab: 'applications',
        };
      }
      deploymentsRefetch();
      setActiveMainTabId('applications');
    } else if (status > 201) {
      setDisableFormFields(false);
    }
  }, [status]);

  const handleRequiredParameters = (paramValue: string, param: string) => {
    let paramObj = { ...parameters };
    paramObj[param] = paramValue;
    setParameters(paramObj);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableFormFields(true);

    if (isEditFlow) {
      // updateApp();
    } else {
      fetchData();
    }
  };

  const handlePrevStep = () => {
    return setStepName('choose');
  };

  return (
    <>
      <br />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ fontSize: '20px' }}>2. Buy Application</div>
        <div>
          <NextButton handleClick={handlePrevStep} text="Back" />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button formAction="none" variant="link" onClick={() => setActiveMainTabId('applications')}>
                Cancel
              </Button>
              <Button variant="primary" loading={loading}>
                Submit
              </Button>
            </SpaceBetween>
          }
          errorText={error || applicationError}
        >
          <SpaceBetween direction="vertical" size="l">
            {applicationLoading ? (
              <Spinner />
            ) : (
              <>
                <FormField label="Friendly name">
                  <Input disabled={disableFormFields} value={name} onChange={(event) => setName(event.detail.value)} />
                </FormField>
                <FormField label="Description">
                  <Input
                    disabled={disableFormFields}
                    value={description}
                    onChange={(event) => setDescription(event.detail.value)}
                  />
                </FormField>

                <FormField label="SNOW Parent CI">
                  <Input
                    disabled={disableFormFields}
                    value={snowParentsCi}
                    onChange={(event) => setSnowParentsCi(event.detail.value)}
                  />
                </FormField>
              </>
            )}
            {!isEditFlow && (
              <>
                <FormField label="Template ID">
                  <Input disabled={true} value={selectedTemplate![0].id} />
                </FormField>

                {requiredParameters.length > 0 && (
                  <FormField label="Parameters" description="Please input the values for each parameter">
                    <ColumnLayout columns={requiredParameters?.length > 2 ? 3 : 2}>
                      {requiredParameters.map((param) => (
                        <div key={param}>
                          <span>{param}</span>
                          <Input
                            disabled={disableFormFields}
                            value={parameters[param]}
                            onChange={(event) => {
                              handleRequiredParameters(event.detail.value, param);
                            }}
                          />
                        </div>
                      ))}
                    </ColumnLayout>
                  </FormField>
                )}

                <FormField label="Region">
                  <Select
                    selectedOption={region}
                    onChange={({ detail }) => {
                      setRegion(detail.selectedOption);
                    }}
                    options={REGION_OPTIONS}
                    placeholder="Choose an option"
                    disabled={disableFormFields}
                    empty="No regions"
                  />
                </FormField>
              </>
            )}
          </SpaceBetween>
        </Form>
      </form>
    </>
  );
};

export default CreateApplicationShop;
