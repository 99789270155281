import React from 'react';
import { Box } from '@cloudscape-design/components';

type EmptyStateProps = {
  title: string;
  subtitle: string;
  action: React.ReactNode;
};

export const EmptyState = ({ title, subtitle, action }: EmptyStateProps) => {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
};
