import { Tabs } from '@cloudscape-design/components';
import AwsAccounts from 'components/catalog-manager/AccountsRegistry/AccountsRegistryTable';
import Templates from 'components/catalog-manager/Templates/TemplatesTable';
import DeploymentsTable from 'components/catalog-manager/Deployments/DeploymentsTable'
import { useCatalogManagerContext } from 'pages/catalog/CatalogManager/CatalogManagerPage';
import ApplicationsTable from '../Applications/ApplicationsTable';

const CatalogMainTabs = () => {
  const { activeMainTabId, setActiveMainTabId } = useCatalogManagerContext();
  return (
    <Tabs
      tabs={[
        {
          label: 'Applications',
          id: 'applications',
          content: <ApplicationsTable type="manager" />,
        },
        {
          label: 'Deployments',
          id: 'deployments',
          content: <DeploymentsTable type="manager" />,
        },
        {
          label: 'Templates',
          id: 'templates',
          content: <Templates />,
        },
        {
          label: 'AWS Accounts',
          id: 'aws-accounts',
          content: <AwsAccounts />,
        },
      ]}
      variant="container"
      activeTabId={activeMainTabId}
      onChange={({ detail }) => setActiveMainTabId(detail.activeTabId)}
    />
  );
};

export default CatalogMainTabs;
