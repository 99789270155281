import { createContext, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { AppLayout, BreadcrumbGroup, Flashbar } from '@cloudscape-design/components';

import TopNav from 'components/common/topnav';
import SideNav from '../sidenav';
import useLink from 'hooks/useLink';

type NotificationProps = {
  type: string;
  dismissible: boolean;
  dismissLabel: string;
  onDismiss: () => void;
  content: React.ReactNode;
  id: string;
};

type BreadcrumbProps = {
  text: string;
  href: string;
};

const PageLayoutContext = createContext({
  setBreadcrumb: (state: BreadcrumbProps[]) => {},
  setNotification: (state: NotificationProps[]) => {},
  setActiveSideNav: (state: string) => {},
  activeSideNav: '',
});

export const usePageLayoutContext = () => useContext(PageLayoutContext);

const Layout = () => {
  const { handleFollow } = useLink();
  const [breadcrumb, setBreadcrumb] = useState<any[]>([]);
  const [notification, setNotification] = useState<any[]>([]);
  const [activeSideNav, setActiveSideNav] = useState('');

  return (
    <PageLayoutContext.Provider
      value={{
        setBreadcrumb,
        setNotification,
        setActiveSideNav,
        activeSideNav,
      }}
    >
      <TopNav />
      <AppLayout
        toolsHide
        navigation={<SideNav />}
        // content={<Outlet context={[setBreadcrumb, setNotification]} />}
        content={<Outlet />}
        notifications={<Flashbar items={notification} />}
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumb}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
            onFollow={handleFollow}
          />
        }
      ></AppLayout>
    </PageLayoutContext.Provider>
  );
};

export default Layout;

// import { createContext, useContext, useState } from 'react';
// // import { Outlet } from 'react-router-dom';

// import {
//     AppLayout,
//     BreadcrumbGroup,
//     Flashbar,
// } from '@cloudscape-design/components';

// import TopNav from 'components/common/topnav';
// import SideNav from '../sidenav';
// import useLink from 'hooks/useLink';
// import PageLayout from './PageLayout';

// type NotificationProps = {
//     type: string;
//     dismissible: boolean;
//     dismissLabel: string;
//     onDismiss: () => void;
//     content: React.ReactNode;
//     id: string;
// };

// type BreadcrumbProps = {
//     text: string;
//     href: string;
// };

// const PageLayoutContext = createContext({
//     setBreadcrumb: (state: BreadcrumbProps[]) => {},
//     setNotification: (state: NotificationProps[]) => {},
// });

// export const usePageLayoutContext = () => useContext(PageLayoutContext);

// const Layout = () => {
//     const { handleFollow } = useLink();
//     const [breadcrumb, setBreadcrumb] = useState<any[]>([]);
//     const [notification, setNotification] = useState<any[]>([]);
//     //<Outlet context={[setBreadcrumb, setNotification]} />

//     return (
//         <PageLayoutContext.Provider value={{ setBreadcrumb, setNotification }}>
//             <TopNav />
//             <AppLayout
//                 toolsHide
//                 navigation={<SideNav />}
//                 content={<PageLayout />}
//                 notifications={<Flashbar items={notification} />}
//                 breadcrumbs={
//                     <BreadcrumbGroup
//                         items={breadcrumb}
//                         expandAriaLabel='Show path'
//                         ariaLabel='Breadcrumbs'
//                         onFollow={handleFollow}
//                     />
//                 }
//             ></AppLayout>
//         </PageLayoutContext.Provider>
//     );
// };

// export default Layout;
