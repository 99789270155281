import { ColumnLayout, SpaceBetween, Box } from '@cloudscape-design/components';
import { useCatalogManagerContext } from 'pages/catalog/CatalogManager/CatalogManagerPage';

const AccountDetails = () => {
  const { selectedAccount } = useCatalogManagerContext();

  return (
    <ColumnLayout columns={2} variant="text-grid">
      {selectedAccount ? (
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Friendly Name</Box>
            <div>{selectedAccount![0]?.installerRoleArn}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Description</Box>
            <div>{selectedAccount![0]?.updatedAt}</div>
          </div>
        </SpaceBetween>
      ) : (
        <i>No data to display</i>
      )}
    </ColumnLayout>
  );
};

export default AccountDetails;
