import { useCollection } from "@cloudscape-design/collection-hooks";
import { Button, ButtonDropdown, Header, Pagination, SpaceBetween, Table, TextFilter } from "@cloudscape-design/components";
import { platformCoreAPI } from "api";
import { EmptyState } from "components/empty-state/EmptyState";
import useFetchWithReactQuery from "hooks/useFetchWithReactQuery";
import { useDeviceContext } from "pages/device-manager/ListDevicesPage";
import { useMemo, useState } from "react";
import { getMatchesCountText } from "utils";
import { COLUMN_DEFINITIONS } from "./table-config";
import DeleteModal from "components/delete-modal";
import { usePageLayoutContext } from "components/common/layout";
import useMutationWithReactQuery from "hooks/useMutationWithReactQuery";
import SendDownlinkModal from "../SendDownlinkModal";

type DeviceDownlink = {
  msgId: string;
  devEUI: string;
  port: number;
  confirmed: boolean;
  data: string;
};

const DownlinkQueue = () => {
  const { selectedDevice } = useDeviceContext();
  const [selectedDownlink, setSelectedDownlink] = useState<any[]>([]);
  const [isSendDownlinkModalOpen, setIsSendDownlinkModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { setNotification } = usePageLayoutContext();

  const { data, isLoading, refetch, isFetching } = useFetchWithReactQuery<DeviceDownlink[]>({
    key: `device-downlink-queue-${selectedDevice?.[0].name}`,
    url: `/device/downlink/${selectedDevice?.[0].name}`,
    axiosInstance: platformCoreAPI,
    enabled: !!selectedDevice?.[0].name,
    placeholderData: [],
  });

  const deviceDownlinks = useMemo(() => data, [data]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    deviceDownlinks || [],
    {
      filtering: {
        empty: (
          <EmptyState
            title="The queue is empty"
            subtitle={`Device ${selectedDevice?.[0].name} has no downlinks in its queue.`}
            action={
              <Button onClick={() => setIsSendDownlinkModalOpen(true)}>
                Send a downlink
              </Button>
            }
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: 10 },
      sorting: {},
    }
  );

  const { mutateAsync: deleteDownlinkQueue, error, isPending } = useMutationWithReactQuery<any, any>({
    url: `/device/downlink/${selectedDevice?.[0].name}`,
    method: 'DELETE',
    api: platformCoreAPI,
    onSuccess: () => {
      setNotification([
        {
          type: 'success',
          content: `Successfully cleared downlink queue`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'downlink-queue-cleared',
        },
      ]);
    },
    onError: () => {
      setNotification([
        {
          type: 'error',
          content: error?.message || `Something went wrong while clearing the queue.`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'downlink-queue-clear-error',
        }
      ]);
    },
  });

  const onDeleteConfirm = async () => {
    if (!deviceDownlinks || deviceDownlinks.length === 0) return;

    await deleteDownlinkQueue({});

    setIsDeleteModalOpen(false);
    refetch();
  }

  return (
    <>
      <Table
        {...collectionProps}
        wrapLines={true}
        loading={isLoading || isFetching}
        stripedRows={true}
        resizableColumns={true}
        onSelectionChange={({ detail }) => setSelectedDownlink(detail.selectedItems)}
        selectedItems={selectedDownlink}
        columnDefinitions={COLUMN_DEFINITIONS}
        items={items || []}
        loadingText="Loading downlinks"
        variant="embedded"
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Find downlinks"
          />
        }
        header={
          <Header
            counter={`(${selectedDownlink.length}/${items.length})`}
            actions={
              <SpaceBetween size="xs" direction="horizontal">
                <Button iconName='refresh' onClick={() => refetch()} loading={isFetching} />
                <ButtonDropdown
                  onItemClick={({ detail }) => {
                    if (detail.id === "send-downlink") {
                      setIsSendDownlinkModalOpen(true);
                    }
                    if (detail.id === "delete-queue") {
                      setIsDeleteModalOpen(true);
                    }
                  }}
                  items={[
                    { text: "Send Downlink", id: "send-downlink" },
                    { text: "Delete Queue", id: "delete-queue", disabled: items.length === 0 },
                  ]}
                >
                  Actions
                </ButtonDropdown>
              </SpaceBetween>
            }
          >
            {selectedDevice?.[0].attributes.friendlyName || selectedDevice?.[0].attributes.friendlyName} Downlinks queue
          </Header>
        }
      />

      <DeleteModal
        visible={isDeleteModalOpen}
        onDiscard={() => setIsDeleteModalOpen(false)}
        onDelete={onDeleteConfirm}
        itemName={"Downlink"}
        moduleName="Queue"
        isLoading={isPending}
      />

      <SendDownlinkModal
        visible={isSendDownlinkModalOpen}
        refetchTableData={refetch}
        setIsVisible={setIsSendDownlinkModalOpen} />

    </>
  );
};

export default DownlinkQueue;