import { Box, ColumnLayout } from '@cloudscape-design/components';
import { catalogAPI } from 'api';
import CatalogDetailsTabs from 'components/catalog-shopping/CatalogDetailsTabs';
import CatalogMainTabs from 'components/catalog-shopping/CatalogMainTabs';
import { usePageLayoutContext } from 'components/common/layout';
import Spinner from 'components/spinner/Spinner';
import {
  API_URL_PATH_LIST_APPLICATIONS,
  API_URL_PATH_LIST_SHOPPING_DEPLOYMENTS,
  API_URL_PATH_LIST_SHOPPING_TEMPLATES,
} from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CatalogDeploymentProps, CatalogTemplateProps, CatalogApplicationProps } from 'types/custom';

const CatalogShoppingContext = createContext({
  allApplications: [] as CatalogApplicationProps[],
  allDeployments: [] as CatalogDeploymentProps[],
  allTemplates: [] as CatalogTemplateProps[],
  applicationsError: '',
  deploymentsError: '',
  templateError: '',
  applicationsLoading: true,
  deploymentsLoading: true,
  templateLoading: true,
  selectedApplication: [] as CatalogApplicationProps[] | undefined,
  selectedDeployment: [] as CatalogDeploymentProps[] | undefined,
  selectedTemplate: [] as CatalogTemplateProps[] | undefined,
  setSelectedApplication: (state: CatalogApplicationProps[]) => { },
  setSelectedDeployment: (state: CatalogDeploymentProps[]) => { },
  setSelectedTemplate: (state: CatalogTemplateProps[]) => { },
  applicationsRefetch: () => { },
  deploymentsRefetch: () => { },
  templateRefetch: () => { },
  activeMainTabId: '',
  setActiveMainTabId: (state: string) => { },
  stepName: '',
  setStepName: (state: string) => { },
});

export const useCatalogShoppingContext = () => useContext(CatalogShoppingContext);

const CatalogShoppingPage = () => {
  const [allApplications, setAllApplications] = useState<CatalogApplicationProps[]>([]);
  const [allDeployments, setAllDeployments] = useState<CatalogDeploymentProps[]>([]);
  const [allTemplates, setAllTemplates] = useState<CatalogTemplateProps[]>([]);

  const [selectedApplication, setSelectedApplication] = useState<CatalogApplicationProps[]>();
  const [selectedDeployment, setSelectedDeployment] = useState<CatalogDeploymentProps[]>();
  const [selectedTemplate, setSelectedTemplate] = useState<CatalogTemplateProps[]>();

  const [activeMainTabId, setActiveMainTabId] = useState('deployments');
  const [stepName, setStepName] = useState<any>('choose');

  const { setBreadcrumb, setNotification } = usePageLayoutContext();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setBreadcrumb([]);
    const locationState = location?.state;
    if (locationState?.action) {
      setNotification([
        {
          type: 'success',
          content: locationState.message,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: `flash-${locationState.action}`,
        },
      ]);

      if (locationState.tab) {
        setActiveMainTabId(locationState.tab);
      }
    }

    navigate(location.pathname, {});
  }, []);

  const {
    response: applicationsResponse,
    loading: applicationsLoading,
    error: applicationsError,
    refetch: applicationsRefetch,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_APPLICATIONS,
    },
    { manual: false }
  );

  const {
    response: deploymentsResponse,
    loading: deploymentsLoading,
    error: deploymentsError,
    refetch: deploymentsRefetch,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_SHOPPING_DEPLOYMENTS,
    },
    { manual: false }
  );

  const {
    response: templateResponse,
    loading: templateLoading,
    error: templateError,
    refetch: templateRefetch,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_SHOPPING_TEMPLATES,
    },
    { manual: false }
  );

  useEffect(() => {
    if (applicationsResponse) {
      setAllApplications(applicationsResponse?.items);
    }
  }, [applicationsResponse]);

  useEffect(() => {
    if (deploymentsResponse) {
      setAllDeployments(deploymentsResponse?.items);
    }
  }, [deploymentsResponse]);

  useEffect(() => {
    if (templateResponse) {
      setAllTemplates(templateResponse?.items);
    }
  }, [templateResponse]);

  return (
    <CatalogShoppingContext.Provider
      value={{
        allApplications,
        allDeployments,
        allTemplates,
        applicationsError,
        deploymentsError,
        templateError,
        applicationsLoading,
        deploymentsLoading,
        templateLoading,
        selectedApplication,
        selectedDeployment,
        selectedTemplate,
        setSelectedDeployment,
        setSelectedTemplate,
        setSelectedApplication,
        applicationsRefetch,
        deploymentsRefetch,
        templateRefetch,
        activeMainTabId,
        setActiveMainTabId,
        stepName,
        setStepName,
      }}
    >
      <ColumnLayout variant="text-grid">
        {applicationsLoading && (
          <>
            <br></br>
            <Spinner />
          </>
        )}
        {applicationsResponse && <CatalogMainTabs />}
      </ColumnLayout>

      {applicationsResponse && activeMainTabId === 'applications' && (
        <>
          <Box margin={{ top: 'xxxl' }}></Box>
          <ColumnLayout variant="text-grid">
            <CatalogDetailsTabs />
          </ColumnLayout>
        </>
      )}
    </CatalogShoppingContext.Provider>
  );
};

export default CatalogShoppingPage;
