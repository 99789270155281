import { Button, ColumnLayout, Container, ContentLayout, Grid, Header } from '@cloudscape-design/components';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const Unauthorized = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-2);
  };

  return (
    <ContentLayout header={<Header variant="h1">Unauthorized</Header>}>
      <Grid gridDefinition={[{ colspan: 12 }]}>
        <Container className="iot-container">
          <br />
          <ColumnLayout variant="text-grid">
            <h2>You do not have access to the requested page</h2>
            <br />
            <Button onClick={goBack}>Go back</Button>
          </ColumnLayout>
        </Container>
      </Grid>
    </ContentLayout>
  );
};

export default Unauthorized;
