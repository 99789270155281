import { Spinner as GlobalSpinner } from '@cloudscape-design/components';

const Spinner = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <GlobalSpinner size="large" />
    </div>
  );
};

export default Spinner;
