import { Tabs } from '@cloudscape-design/components';
import DataModel from '../DataModel';
import DeviceTypeDetails from '../DeviceTypeDetails';
import Measurements from '../Measurements';
import Downlinks from '../Downlinks';

const DeviceTypesTabs = () => {
  return (
    <Tabs
      tabs={[
        {
          label: 'Details',
          id: 'details',
          content: <DeviceTypeDetails />,
        },
        {
          label: 'Data Model',
          id: 'data-model',
          content: <DataModel />,
        },
        {
          label: 'Measurements',
          id: 'measurements',
          content: <Measurements />,
        },
        {
          label: 'Downlinks',
          id: 'downlinks',
          content: <Downlinks />,
        },
      ]}
      className="tabs-container"
      variant="container"
    />
  );
};

export default DeviceTypesTabs;
