import { useNavigate, useLocation } from "react-router-dom";
import { ColumnLayout, Spinner, Box } from "@cloudscape-design/components";
import { useState, useEffect, useMemo, useContext, createContext } from "react";

import useFetch from "hooks/useFetch";
import { catalogAPI, platformCoreAPI } from "api";
import { extractFilteringAttributes } from "utils";
import DeviceTabs from "components/device-manager/DeviceTabs";
import DeviceTable from "components/device-manager/DeviceTable";
import { usePageLayoutContext } from "components/common/layout";
import { DeviceProps, CatalogApplicationProps } from "types/custom";
import { API_URL_PATH_LIST_APPLICATIONS, API_URL_PATH_DEVICE } from "constants/urls";


const DeviceContext = createContext({
  allItems: [] as any[],
  loading: false,
  error: '',
  setSelectedDevice: (state: DeviceProps[]) => { },
  selectedDevice: [] as DeviceProps[] | undefined,
  refetch: () => { },
});

export const useDeviceContext = () => useContext(DeviceContext);

const ListDevicesPage = () => {
  const [selectedDevice, setSelectedDevice] = useState<DeviceProps[]>([]);
  const [allApplications, setAllApplications] = useState<CatalogApplicationProps[]>([]);

  const [allItems, setAllItems] = useState<DeviceProps[]>([]);

  const { setBreadcrumb, setNotification } = usePageLayoutContext();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setBreadcrumb([]);
    if (location?.state?.action) {
      setNotification([
        {
          type: 'success',
          content: location.state.message,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: `flash-${location.state.action}`,
        },
      ]);
    }

    navigate(location.pathname, {});
  }, []);

  const {
    response: applicationsResponse,
    loading: applicationLoading,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_APPLICATIONS,
    },
    { manual: false }
  );

  const { response, loading, status, error, fetchData } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'GET',
      url: API_URL_PATH_DEVICE,
    },
    { manual: false }
  );


  useMemo(() => {
    if (allItems.length !== 0) {
      allItems.forEach((device: DeviceProps) => {
        const application = allApplications.find((application: CatalogApplicationProps) => application.id === device.attributes.iotApplicationId)
        application ? device.attributes.iotApplicationName = application.name : device.attributes.iotApplicationName = device.attributes.iotApplicationId;
      })
      setAllItems(allItems);
    }
  }, [allApplications, allItems])

  useEffect(() => {
    if (status === 200) {
      setAllItems(extractFilteringAttributes(response.results));
    }
  }, [status, response]);

  useEffect(() => {
    const updatedSelectedItem = allItems.find((device) => device.name === selectedDevice[0].name)

    if (updatedSelectedItem) {
      setSelectedDevice([updatedSelectedItem])
    }
  }, [allItems])

  useEffect(() => {
    if (applicationsResponse) {
      setAllApplications(applicationsResponse?.items || []);
    }
  }, [applicationsResponse]);

  return (
    <DeviceContext.Provider
      value={{
        loading,
        allItems,
        error,
        setSelectedDevice,
        selectedDevice,
        refetch: fetchData,
      }}
    >
      <ColumnLayout variant="text-grid">
        {(loading || applicationLoading) && (!response || !applicationsResponse) && (
          <div style={{ marginTop: "50px" }}>
            <Spinner />
          </div>
        )}
        {response && <DeviceTable />}
        {response && applicationsResponse && selectedDevice![0]?.name !== undefined && <DeviceTabs />}
      </ColumnLayout>
    </DeviceContext.Provider>
  );
};

export default ListDevicesPage;
