
import { SpaceBetween, Button, Header, FormField, Input, Form, Select, SelectProps } from '@cloudscape-design/components';
import { catalogAPI } from 'api';
import { usePageLayoutContext } from 'components/common/layout';
import Spinner from 'components/spinner/Spinner';
import {
  URL_PATH_CATALOG_MANAGER,
  API_URL_PATH_LIST_INTEGRATION,
  URL_PATH_CREATE_CATALOG_INTEGRATION,
} from 'constants/urls';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IntegrationStatus, IntegrationTypes } from 'types/custom';
import { selectIntegrationEnum, selectIntegrationStatusProps,selectIntegrationTypes } from 'utils';

const CreateIntegration = () => {
  const [id, setId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [typeToUpdate, setTypeToUpdate] = useState<string>('');
  const [statusIntegration, setStatusIntegration] = useState<SelectProps.Option>({
    value: '',
    label: '',
  });
  const [type, setType] = useState<SelectProps.Option>({
    value: '',
    label: '',
  });

  const [isEditFlow, setIsEditFlow] = useState(false);
  const [isDuplicateFlow, setIsDuplicateFlow] = useState(false);
  const [disableFormFields, setDisableFormFields] = useState(false);
  const [selectedApplicationId, setSelectedApplicationId] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  let params = useParams();

  const { setBreadcrumb } = usePageLayoutContext();

  useEffect(() => {
    let breadcrumbItems = [];

    if (
      (location && location.state?.action === 'edit') ||
      (location.pathname && location.pathname.indexOf('edit') > -1)
    ) {

      breadcrumbItems.push({
        text: 'Edit',
        href: '',
      });

      setIsEditFlow(true);
    } else if(location && location.state?.action === 'create') {
      setSelectedApplicationId(location.state.applicationId)
      breadcrumbItems.push({
        text: 'Create',
        href: '/catalog/admin/integration/create',
      });
    } else if(
      (location && location.state?.action === 'duplicate') ||
      (location.pathname && location.pathname.indexOf('duplicate') > -1)
    ) {
      setIsDuplicateFlow(true);
      setSelectedApplicationId(location.state.applicationId)
      breadcrumbItems.push({
        text: 'Duplicate',
        href: '',
      });
    }

    setBreadcrumb([
      {
        text: 'Integration',
        href: API_URL_PATH_LIST_INTEGRATION,
      },
      ...breadcrumbItems,
    ]);
  }, []);

  const {
    loading: integrationLoading,
    error: integrationError,
    fetchData: getIntegration,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_INTEGRATION,
      param: params?.id,
    },
    { manual: true }
  );

  useEffect(() => {
    if (isEditFlow) {
      getIntegration()
        .then((integration) => {
          if (integration?.data) {
            setId(integration.data.id);
            setDescription(integration.data.description);
            setName(integration.data.name);
            setStatusIntegration(selectIntegrationStatusProps(integration.data));
            setTypeToUpdate(integration.data.type);
          } else {
            const navUrl = URL_PATH_CREATE_CATALOG_INTEGRATION;
            navigate(navUrl);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (isDuplicateFlow) {
      getIntegration()
        .then((integration) => {
          if (integration?.data) {
            setDescription(integration.data.description);
            setName(integration.data.name);
            setType(selectIntegrationTypes(integration.data));
          } else {
            const navUrl = URL_PATH_CREATE_CATALOG_INTEGRATION;
            navigate(navUrl);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isEditFlow, isDuplicateFlow]);

  const {
    error: updateIntegrationError,
    loading: updateIntegrationLoading,
    status: updateIntegrationStatus,
    fetchData: updateApp,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'PATCH',
      url: `${API_URL_PATH_LIST_INTEGRATION}/${id}`,
      headers: {
        'Content-Type': 'integration/json',
      },
      data: {
        description,
        name,
        status: statusIntegration.label === "ENABLED" ,
        type: typeToUpdate,
      },
    },
    { manual: true }
  );


  const { error, loading, status, fetchData } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'POST',
      url: API_URL_PATH_LIST_INTEGRATION,
      headers: {
        'Content-Type': 'integration/json',
      },
      data: {
        applicationId: selectedApplicationId,
        name,
        description,
        type: type.label,
      },
    },
    { manual: true }
  );

  useEffect(() => {

  }, []);

  useEffect(() => {
    if (status === 201 || updateIntegrationStatus === 200) {
      let stateObj = {};

      if (isEditFlow) {
        stateObj = {
          action: 'update-integration',
          status: 'success',
          message: `Successfully updated integration ${id}`,
          tab: 'applications',
          detailsTab: 'integrationsDetails',
        };
      }else if(isDuplicateFlow){
        stateObj = {
          action: 'duplicate-integration',
          status: 'success',
          message: `Successfully duplicated integration ${name}`,
          tab: 'applications',
          detailsTab: 'integrationsDetails',
        };
      }else {
        stateObj = {
          action: 'create-integration',
          status: 'success',
          message: `Successfully created integration ${id}`,
          tab: 'applications',
          detailsTab: 'integrationsDetails',
        };
      }

      navigate(URL_PATH_CATALOG_MANAGER, {
        state: stateObj,
      });
    } else if (status > 201 || updateIntegrationStatus > 201) {
      setDisableFormFields(false);
    }
  }, [status, updateIntegrationStatus]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableFormFields(true);

    if (isEditFlow) {
      await updateApp();
    } else {
      await fetchData();
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link" onClick={() => navigate(URL_PATH_CATALOG_MANAGER, {})}>
              Cancel
            </Button>
            <Button variant="primary" loading={loading || updateIntegrationLoading}>
              Submit
            </Button>
          </SpaceBetween>
        }
        header={
          <Header variant="h2" description="">
            {isEditFlow ? 'Update' : 'Create'} a Integration
          </Header>
        }
        errorText={error || integrationError || updateIntegrationError}
      >
        <SpaceBetween direction="vertical" size="l">
          {integrationLoading ? (
            <Spinner />
          ) : (
            <>
              <FormField label="Name">
                <Input
                  disabled={disableFormFields}
                  value={name}
                  onChange={(event) => setName(event.detail.value)}
                />
              </FormField>
              <FormField label="Description">
                <Input
                  disabled={disableFormFields}
                  value={description}
                  onChange={(event) => setDescription(event.detail.value)}
                />
              </FormField>
              {!isEditFlow && 
                  <FormField label="Type">
                    <Select
                      selectedOption={type}
                      onChange={({ detail }) => {
                        setType(detail.selectedOption);
                      }}
                      options={selectIntegrationEnum(IntegrationTypes)}
                      loadingText="Loading instances"
                      placeholder="Choose a status"
                      disabled={disableFormFields}
                      empty="No status"
                    />
                  </FormField>
                }
              {isEditFlow && 
                  <FormField label="Status">
                    <Select
                      selectedOption={statusIntegration}
                      onChange={({ detail }) => {
                        setStatusIntegration(detail.selectedOption);
                      }}
                      options={selectIntegrationEnum(IntegrationStatus)}
                      loadingText="Loading instances"
                      placeholder="Choose a status"
                      disabled={disableFormFields}
                      empty="No status"
                    />
                  </FormField>
                }
            </>
          )}
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default CreateIntegration;
