import { Container } from '@cloudscape-design/components';
import CreateTemplate from 'components/catalog-manager/Templates/CreateTemplate';

const CreateTemplatePage = () => {
  return (
    <Container>
      <CreateTemplate />
    </Container>
  );
};

export default CreateTemplatePage;
