import { TableProps } from "@cloudscape-design/components";
import { DeviceProps } from "types/custom";

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<any>[] = [
  {
    id: 'name',
    header: 'Name',
    cell: (item: DeviceProps) => item?.name,
    sortingField: 'name',
    width: '22%',
  },
  {
    id: 'friendlyName',
    header: 'Friendly Name',
    cell: (item: DeviceProps) => item?.attributes?.friendlyName,
    width: '33%',
  },
  {
    id: 'device-state',
    header: 'Status',
    cell: (item: DeviceProps) => item?.attributes?.deviceState,
    width: '13%',
  },
  // This will be only Application, when integration are created
  {
    id: 'application-id',
    header: 'Application / Deployment',
    cell: (item: DeviceProps) => item?.attributes?.iotApplicationName,
    width: '22%',
  },
  {
    id: 'installed',
    header: 'Installed',
    cell: (item: DeviceProps) => item?.attributes?.currentPath ? 'Yes' : 'No',
    width: '10%',
  },
];
