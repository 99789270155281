export const REGION_OPTIONS = [
  { label: 'us-west-2', value: 'us-west-2' },
  { label: 'us-east-1', value: 'us-east-1' },
  { label: 'eu-west-1', value: 'eu-west-1' },
  { label: 'eu-central-1', value: 'eu-central-1' },
  { label: 'ap-northeast-2', value: 'ap-northeast-2' },
  { label: 'ap-southeast-1', value: 'ap-southeast-1' },
];

export const ACCESS_MAPS = [
  { label: 'platformCore', value: 'platformCore' },
  { label: 'location', value: 'location' },
  { label: 'ingestion', value: 'ingestion' },
  { label: 'catalog', value: 'catalog' },
];
