import { Tabs } from '@cloudscape-design/components';
import DeploymentDetails from './DeploymentDetails';

const CatalogDetailsTabs = () => {
  return (
    <Tabs
      tabs={[
        {
          label: 'Details',
          id: 'details',
          content: <DeploymentDetails type="shopping" />,
        },
      ]}
      className="tabs-container"
      variant="container"
    />
  );
};

export default CatalogDetailsTabs;
