import { Container } from '@cloudscape-design/components';
import CreateTag from 'components/physical-devices/CreateTag';

const CreateTagPage = () => {
  return (
    <Container className="iot-container">
      <CreateTag />
    </Container>
  );
};

export default CreateTagPage;
