import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, SpaceBetween, Button, Header, FormField, Input } from '@cloudscape-design/components';

import { API_URL_PATH_CREATE_TAG } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { platformCoreAPI } from 'api';

const CreateTag = () => {
  const [id, setId] = useState('');
  const [friendlyName, setFriendlyName] = useState('');
  const [description, setDescription] = useState('');
  const [disableFormFields, setDisableFormFields] = useState(false);

  const navigate = useNavigate();

  const { error, loading, status, fetchData } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'POST',
      url: API_URL_PATH_CREATE_TAG,
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
      data: {
        id,
        friendlyName,
        description,
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (status === 201) {
      navigate('/lns/list', {
        state: {
          action: 'create-tag',
          status: 'success',
          message: `Successfully created tag ${friendlyName}`,
        },
      });
    } else if (status > 201) {
      setDisableFormFields(false);
    }
  }, [status]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableFormFields(true);
    fetchData();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link" onClick={() => navigate('/lns/list', {})}>
              Cancel
            </Button>
            <Button variant="primary" loading={loading}>
              Submit
            </Button>
          </SpaceBetween>
        }
        header={
          <Header variant="h2" description="">
            Create a Tag
          </Header>
        }
        errorText={error}
      >
        <SpaceBetween direction="vertical" size="l">
          <FormField label="Tag Id">
            <Input disabled={disableFormFields} value={id} onChange={(event) => setId(event.detail.value)} />
          </FormField>
          <FormField label="Friendly name">
            <Input
              disabled={disableFormFields}
              value={friendlyName}
              onChange={(event) => setFriendlyName(event.detail.value)}
            />
          </FormField>
          <FormField label="Description">
            <Input
              disabled={disableFormFields}
              value={description}
              onChange={(event) => setDescription(event.detail.value)}
            />
          </FormField>
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default CreateTag;
