import { Tabs } from '@cloudscape-design/components';

import DeviceDetails from '../DeviceDetails';
import DownlinkQueue from '../DownlinkQueue';

const DeviceTypesTabs = () => {
  return (
    <Tabs
      tabs={[
        {
          label: 'Details',
          id: 'details',
          content: <DeviceDetails />,
        },
        {
          label: 'Downlink Queue',
          id: 'downlink queue',
          content: <DownlinkQueue />,
        },
      ]}
      className="tabs-container"
      variant="container"
    />
  );
};

export default DeviceTypesTabs;
