import { ColumnLayout, Box, SpaceBetween } from '@cloudscape-design/components';
import { useDeviceContext } from 'pages/device-manager/ListDevicesPage';

const DeviceDetails = () => {
  const { selectedDevice } = useDeviceContext();
  return (
    <ColumnLayout columns={2} variant="text-grid">
      <SpaceBetween size="l">
      <div>
          <Box variant="awsui-key-label">Friendly Name</Box>
          <div>{selectedDevice![0]?.attributes?.friendlyName}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Description</Box>
          <div>{selectedDevice![0]?.description}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Device Type ID</Box>
          <div>{selectedDevice![0]?.attributes?.deviceTypeId}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Device State</Box>
          <div>{selectedDevice![0]?.attributes.deviceState}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Tektelic Device ID</Box>
          <div>{selectedDevice![0]?.attributes?.tektelicDeviceId}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Physical Device ID</Box>
          <div>{selectedDevice![0]?.attributes.physicalDeviceId}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Serial Number</Box>
          <div>{selectedDevice![0]?.attributes.serialNumber}</div>
        </div>
      </SpaceBetween>
      <SpaceBetween size="l">
        <div>
          <Box variant="awsui-key-label">Application Id</Box>
          <div>{selectedDevice![0]?.attributes?.iotApplicationName || '-'}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Friendly Current Path</Box>
          <div>{selectedDevice![0]?.attributes?.friendlyCurrentPath || '-'}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Group Path</Box>
          <div>{selectedDevice![0]?.groupPath}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Invoice ID</Box>
          <div>{selectedDevice![0]?.attributes.invoiceId}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Lookup ID</Box>
          <div>{selectedDevice![0]?.attributes.lookupId}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Current Path</Box>
          <div>{selectedDevice![0]?.attributes.currentPath || '-'}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">ServiceNOW ID</Box>
          <div>{selectedDevice![0]?.attributes?.snowSysId || '-'}</div>
        </div>
      </SpaceBetween>
    </ColumnLayout>
  );
};

export default DeviceDetails;
