import React from 'react';
import { ColumnLayout, SpaceBetween, Box } from '@cloudscape-design/components';
import { useDeviceTypesContext } from 'pages/device-type-manager/ListDeviceTypesPage';

const DataModel = () => {
  const { selectedDeviceType } = useDeviceTypesContext();
  return (
    <ColumnLayout columns={2} variant="text-grid">
      <SpaceBetween size="l">
        <div>
          <Box variant="awsui-key-label">Normalizer ARN</Box>
          <div>{selectedDeviceType![0]?.attributes?.normalizer || '-'}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Decoder ARN</Box>
          <div>{selectedDeviceType![0]?.attributes?.decoder || '-'}</div>
        </div>
      </SpaceBetween>
      <SpaceBetween size="l"></SpaceBetween>
    </ColumnLayout>
  );
};

export default DataModel;
