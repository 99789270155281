import { Container } from '@cloudscape-design/components';
import CreateDeployment from 'components/catalog-manager/Deployments/CreateDeployment';

const UpdateDeploymentPage = () => {
  return (
    <Container >
      <CreateDeployment />
    </Container>
  );
};

export default UpdateDeploymentPage;
