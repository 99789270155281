export const COLUMN_DEFINITIONS = [
  {
    id: 'name',
    header: 'Name',
    cell: (item: any) => item?.name,
    sortingField: 'name',
  },
  {
    id: 'description',
    header: 'Description',
    cell: (item: any) => item?.description,
    sortingField: 'description',
  },
  {
    id: 'template',
    header: 'Template',
    cell: (item: any) => item?.templateName,
    sortingField: 'templateId',
  },
  {
    id: 'accountId',
    header: 'AWS Account ID',
    cell: (item: any) => item?.accountId,
    sortingField: 'accountId',
  },
  {
    id: 'region',
    header: 'Region',
    cell: (item: any) => item?.region,
    sortingField: 'region',
  },
  {
    id: 'state',
    header: 'State',
    cell: (item: any) => item?.state,
    sortingField: 'region',
  },
];
