import React, { useEffect, useState } from 'react';
import { Form, SpaceBetween, Button, Header, FormField, Input, Select } from '@cloudscape-design/components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useFetch from 'hooks/useFetch';
import { selectFewerProps } from 'utils';
import { platformCoreAPI } from 'api';

const CreateMqttClient = () => {
  const [id, setId] = useState('');
  const [friendlyName, setFriendlyName] = useState('');
  const [description, setDescription] = useState('');
  const [isPassthrough, setIsPassthrough] = useState<any>();
  const [publishTopicsText, setPublishTopicsText] = useState('');
  const [subscribeTopicsText, setSubscribeTopicsText] = useState('');
  const [disableFormFields, setDisableFormFields] = useState(false);
  const [converters, setConverters] = useState<any>([]);
  const [deviceGroups, setDeviceGroups] = useState<any>([]);
  const [selectedConverter, setSelectedConverter] = useState<any>();
  const [selectedDeviceGroup, setSelectedDeviceGroup] = useState<any>();

  const navigate = useNavigate();
  const location = useLocation();

  let params = useParams();

  const {
    response: convertersResponse,
    loading: convertersLoading,
    error: convertersError,
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'GET',
      url: '/mqtt-client/tektelic/physicaldevice/',
      params: [params?.physicaldevice, 'converters'],
    },
    { manual: false }
  );

  const {
    response: deviceGroupsResponse,
    loading: deviceGroupsLoading,
    error: deviceGroupsError,
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'GET',
      url: '/mqtt-client/tektelic/physicaldevice/',
      params: [params?.physicaldevice, 'devicegroups'],
    },
    { manual: false }
  );

  useEffect(() => {
    if (convertersResponse && Array.isArray(convertersResponse)) {
      const convertersList = convertersResponse.map(selectFewerProps);
      setConverters(convertersList);
    }
  }, [convertersResponse]);

  useEffect(() => {
    if (deviceGroupsResponse && Array.isArray(deviceGroupsResponse)) {
      const deviceGroupsList = deviceGroupsResponse.map(selectFewerProps);
      setDeviceGroups(deviceGroupsList);
    }
  }, [deviceGroupsResponse]);

  const { error, loading, status, fetchData } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'POST',
      url: `/mqtt-client/tektelic`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        id,
        friendlyName,
        description,
        physicalDeviceId: params?.physicaldevice,
        converterId: selectedConverter?.value,
        converterFriendlyName: selectedConverter?.label,
        deviceGroupId: selectedDeviceGroup?.value,
        deviceGroupFriendlyName: selectedDeviceGroup?.label,
        isPassthrough: isPassthrough?.value === 'true' ? true : false,
        publishTopics: publishTopicsText.split(','),
        subscribeTopics: subscribeTopicsText.split(','),
      },
    },
    { manual: true }
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableFormFields(true);
    fetchData();
  };

  useEffect(() => {
    if (status === 201) {
      navigate('/lns/list', {
        state: {
          action: 'create-mqtt',
          status: 'success',
          message: `Successfully created MQTT client ${friendlyName}`,
          selectedItem: location.state?.selectedItem,
          activeTabId: 'mqtt',
        },
      });
    } else if (status > 201 && error) {
      setDisableFormFields(false);
    }
  }, [status]);

  return (
    <form onSubmit={handleSubmit}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link" onClick={() => navigate('/lns/list', {})}>
              Cancel
            </Button>
            <Button variant="primary" loading={loading}>
              Submit
            </Button>
          </SpaceBetween>
        }
        header={
          <Header variant="h2" description="">
            Create MQTT Client
          </Header>
        }
        errorText={error}
      >
        <SpaceBetween direction="vertical" size="l">
          <FormField label="ID">
            <Input disabled={disableFormFields} value={id} onChange={(event) => setId(event.detail.value)} />
          </FormField>
          <FormField label="Friendly name">
            <Input
              disabled={disableFormFields}
              value={friendlyName}
              onChange={(event) => setFriendlyName(event.detail.value)}
            />
          </FormField>
          <FormField label="Description">
            <Input
              disabled={disableFormFields}
              value={description}
              onChange={(event) => setDescription(event.detail.value)}
            />
          </FormField>
          <FormField label="Passthrough">
            <Select
              selectedOption={isPassthrough}
              placeholder="Choose an option"
              onChange={({ detail }) => setIsPassthrough(detail.selectedOption)}
              options={[
                { label: 'true', value: 'true' },
                { label: 'false', value: 'false' },
              ]}
            />
          </FormField>
          <FormField
            label={
              <span>
                Converter <i>- optional</i>{' '}
              </span>
            }
          >
            <Select
              selectedOption={selectedConverter}
              onChange={({ detail }) => setSelectedConverter(detail.selectedOption)}
              options={converters}
              loadingText="Loading instances"
              placeholder="Choose an option"
              statusType={convertersLoading ? 'loading' : 'finished'}
              empty="No converters"
            />
          </FormField>
          <FormField
            label={
              <span>
                Device Group <i>- optional</i>{' '}
              </span>
            }
          >
            <Select
              selectedOption={selectedDeviceGroup}
              onChange={({ detail }) => setSelectedDeviceGroup(detail.selectedOption)}
              options={deviceGroups}
              loadingText="Loading instances"
              placeholder="Choose an option"
              statusType={deviceGroupsLoading ? 'loading' : 'finished'}
              empty="No device groups"
            />
          </FormField>
          <FormField
            label={
              <span>
                Publish Topics <i>- optional</i>{' '}
              </span>
            }
          >
            <Input
              disabled={disableFormFields}
              value={publishTopicsText}
              onChange={(event) => setPublishTopicsText(event.detail.value)}
            />
          </FormField>
          <FormField
            label={
              <span>
                Subscribe Topics <i>- optional</i>{' '}
              </span>
            }
          >
            <Input
              disabled={disableFormFields}
              value={subscribeTopicsText}
              onChange={(event) => setSubscribeTopicsText(event.detail.value)}
            />
          </FormField>
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default CreateMqttClient;
