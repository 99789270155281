import { useMemo, useState } from "react";
import { Button, ButtonDropdown, Header, Pagination, SpaceBetween, Table, TextFilter } from "@cloudscape-design/components";

import { platformCoreAPI } from "api";
import DeleteModal from "components/delete-modal";
import { usePageLayoutContext } from "components/common/layout";
import useMutationWithReactQuery from "hooks/useMutationWithReactQuery";
import { useDeviceTypesContext } from "pages/device-type-manager/ListDeviceTypesPage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { EmptyState } from "components/empty-state/EmptyState";
import { getMatchesCountText } from "utils";
import CreateEditDownlinkModal from "./CreateEditDownlinkModal";
import { COLUMN_DEFINITIONS } from "./table-config";
import { DeviceTypeResponse, Downlink } from "types/custom";

const Downlinks = () => {
  const { selectedDeviceType, refetch } = useDeviceTypesContext();
  const [selectedDownlink, setSelectedDownlink] = useState<any[]>([]);
  const [isDownlinkModalOpen, setIsDownlinkModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { setNotification } = usePageLayoutContext();

  const { mutateAsync: updateDeviceType, error, isPending } = useMutationWithReactQuery<DeviceTypeResponse, { downlinks: Downlink[] }>({
    url: `${platformCoreAPI.defaults.baseURL}/device-type/${selectedDeviceType?.[0].name}`,
    method: 'PATCH',
    api: platformCoreAPI,
    onSuccess: () => {
      setNotification([
        {
          type: 'success',
          content: `Successfully deleted downlink`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'downlink-deleted',
        },
      ]);
    },
    onError: () => {
      setNotification([
        {
          type: 'error',
          content: error?.message || `Something went wrong while deleting downlink`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'downlink-deleted-error',
        }
      ]);
    },
  });

  const transformedDownlinks = useMemo(() => {
    setSelectedDownlink([]);
    if (!selectedDeviceType || !selectedDeviceType[0].downlinks) return [];

    return selectedDeviceType[0].downlinks?.map((downlink: any) => ({
      ...downlink,
      deviceType: downlink.attributes?.friendlyName || "",
      deviceTypeId: downlink.deviceTypeId || ""
    }));
  }, [selectedDeviceType]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    transformedDownlinks,
    {
      filtering: {
        empty: (
          <EmptyState
            title="No downlinks"
            subtitle={`Device type ${selectedDeviceType?.[0].description || selectedDeviceType?.[0].name} has no downlinks.`}
            action={
              <Button onClick={() => setIsDownlinkModalOpen(true)}>
                Create a downlink
              </Button>
            }
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: 10 },
      sorting: {},
    }
  );

  const onDeleteConfirm = async () => {
    const deviceTypeDownlinks = selectedDeviceType?.[0].downlinks;
    if (!deviceTypeDownlinks || !selectedDownlink[0]) return;

    const updatedDownlinks = deviceTypeDownlinks.filter((downlink: any) => downlink.name !== selectedDownlink[0].name);
    await updateDeviceType({ downlinks: updatedDownlinks });

    setIsDeleteModalOpen(false);
    refetch();
  }

  return (
    <>
      <Table
        {...collectionProps}
        wrapLines={true}
        loading={isPending}
        stripedRows={true}
        resizableColumns={true}
        onSelectionChange={({ detail }) => setSelectedDownlink(detail.selectedItems)}
        selectedItems={selectedDownlink}
        columnDefinitions={COLUMN_DEFINITIONS}
        items={items || []}
        loadingText="Loading downlinks"
        selectionType="single"
        variant="embedded"
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Find downlinks"
          />
        }
        header={
          <Header
            counter={`(${selectedDownlink.length}/${transformedDownlinks.length})`}
            actions={
              <SpaceBetween size="xs" direction="horizontal">
                <Button onClick={() => {
                  setIsEditing(false);
                  setIsDownlinkModalOpen(true);
                }}>Create</Button>
                <ButtonDropdown
                  onItemClick={({ detail }) => {
                    if (detail.id === "edit") {
                      setIsEditing(true);
                      setIsDownlinkModalOpen(true);
                    }
                    if (detail.id === "delete") {
                      if (selectedDownlink[0]) {
                        setIsDeleteModalOpen(true);
                      }
                    }
                  }}
                  items={[
                    { text: "Edit", id: "edit", disabled: selectedDownlink.length !== 1 },
                    { text: "Delete", id: "delete", disabled: selectedDownlink.length === 0 },
                  ]}
                >
                  Actions
                </ButtonDropdown>
              </SpaceBetween>
            }
          >
            {selectedDeviceType?.[0].description || selectedDeviceType?.[0].name} Downlinks
          </Header>
        }
      />

      <CreateEditDownlinkModal
        selectedDownlink={selectedDownlink[0]}
        isOpen={isDownlinkModalOpen}
        isEditing={isEditing}
        onClose={() => setIsDownlinkModalOpen(false)} />

      <DeleteModal
        visible={isDeleteModalOpen}
        onDiscard={() => setIsDeleteModalOpen(false)}
        onDelete={onDeleteConfirm}
        itemName={selectedDownlink[0]?.name}
        itemCount={1}
        moduleName="Downlink"
        isLoading={isPending}
      />
    </>
  );
};

export default Downlinks;
