export const COLUMN_DEFINITIONS = [
  {
    id: 'id',
    header: 'Account ID',
    cell: (item: any) => item?.accountId,
    sortingField: 'id',
  },
  {
    id: 'iotApplicationId',
    header: 'IoT Application ID',
    cell: (item: any) => item?.iotApplicationId,
    sortingField: 'iotApplicationId',
  },
  {
    id: 'state',
    header: 'Status',
    cell: (item: any) => item?.state,
    sortingField: 'state',
  },
];
