import { ContentLayout, Container, Header, Grid } from '@cloudscape-design/components';
import { usePageLayoutContext } from 'components/common/layout';
import { Outlet } from 'react-router-dom';
import React, { useEffect } from 'react';

const PhysicalDeviceManager = () => {
  const { setActiveSideNav } = usePageLayoutContext();
  useEffect(() => {
    setActiveSideNav(window.location.pathname);
  }, []);

  return (
    <ContentLayout header={<Header variant="h1">Physical Device Manager</Header>}>
      <Grid gridDefinition={[{ colspan: 12 }]}>
        {/* <Container>
                    <br /> */}
        <Outlet />
        {/* </Container> */}
      </Grid>
    </ContentLayout>
  );
};

export default PhysicalDeviceManager;
