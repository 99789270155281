export const COLUMN_DEFINITIONS = [
  {
    id: "name",
    header: "Name",
    cell: (item: { name: any; }) => (item.name),
    sortingField: "name",
  },
  {
    id: "type",
    header: "Type",
    cell: (item: { type: any; }) => (item.type),
    sortingField: "type",
  },
  {
    id: "unit",
    header: "Unit",
    cell: (item: { unit: any; }) => item.unit || "N/A",
    sortingField: "unit",
  },
  {
    id: "favourite",
    header: "Favourite",
    cell: (item: { favourite: any; }) => item.favourite ? "Yes" : "No",
    sortingField: "favourite",
  },
];