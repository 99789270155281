import { useEffect, useState } from 'react';
import {
  ColumnLayout,
  SpaceBetween,
  Box,
  IconProps,
  Select,
  SelectProps,
  Button,
  FormField,
  TokenGroup,
  TextContent,
  Spinner,
} from '@cloudscape-design/components';

import { usePhysicalDevicesContext } from 'pages/physical-device-manager/ListPhysicalDevicesPage';
import useFetch from 'hooks/useFetch';
import { AllTagsResponseProps, PhysicalDeviceResponseProps } from 'types/custom';
import { platformCoreAPI } from 'api';

type SelectedTagsProps = {
  label?: string;
  value?: string;
  iconName?: IconProps.Name;
  description?: string;
};

const Tags = () => {
  const [selectedTag, setSelectedTag] = useState<any>();
  const [allTags, setAllTags] = useState<any>([]);
  const [attachedTags, setAttachedTags] = useState<any>([]);
  const [detachTagName, setDetachTagName] = useState<any>();

  const { selectedItem: physicalDevice } = usePhysicalDevicesContext();

  const {
    response: getAllTagsResponse,
    loading: getAllTagsLoading,
    status: getAllTagsStatus,
    error: getAllTagsError,
    fetchData: fetchAllTags,
  }: {
    response: AllTagsResponseProps[];
    loading: boolean;
    status: number;
    error: string;
    fetchData: () => void;
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'GET',
      url: '/tag',
    },
    { manual: false }
  );

  const {
    response: physicalDeviceTagsResponse,
    loading: physicalDeviceTagsLoading,
    status: physicalDeviceTagsStatus,
    error: physicalDeviceTagsError,
    refetch: physicalDeviceTagsRefetch,
    fetchData: fetchPhysicalDeviceTags,
  }: {
    response: PhysicalDeviceResponseProps;
    loading: boolean;
    status: number;
    error: string;
    refetch: () => void;
    fetchData: () => void;
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'GET',
      url: `/physical-device/${physicalDevice![0]?.attributes?.friendlyName}`,
    },
    { manual: false }
  );

  const {
    response: attachTagsResponse,
    loading: attachTagsLoading,
    status: attachTagsStatus = 0,
    error: attachTagsError,
    fetchData: fetchAttachTags,
  }: {
    response: undefined;
    loading: boolean;
    status: number;
    error: string;
    fetchData: (method: string) => Promise<any>;
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'PUT',
      url: `/physical-device/${physicalDevice![0]?.attributes?.friendlyName}/tag/${selectedTag?.value}`,
    },
    { manual: true }
  );

  const {
    loading: detachTagsLoading,
    status: detachTagsStatus,
    error: detachTagsError,
    fetchData: fetchDetachTags,
  }: {
    loading: boolean;
    status: number;
    error: string;
    fetchData: (method: string) => Promise<any>;
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'DELETE',
      url: `/physical-device/${physicalDevice![0]?.attributes?.friendlyName}${detachTagName}`,
    },
    { manual: true }
  );

  useEffect(() => {
    setAllTags(
      getAllTagsResponse?.map((tag, i) => {
        return {
          label: tag.name,
          value: tag.name,
        };
      })
    );
  }, [getAllTagsStatus, getAllTagsResponse]);

  useEffect(() => {
    setAttachedTags(
      physicalDeviceTagsResponse?.groups?.tag?.map((tag, i) => {
        return {
          label: tag,
        };
      })
    );
  }, [physicalDeviceTagsResponse, physicalDeviceTagsStatus]);

  useEffect(() => {
    if (physicalDeviceTagsResponse) {
      physicalDeviceTagsRefetch();
    }
  }, [physicalDevice]);

  useEffect(() => {
    if (detachTagName && physicalDeviceTagsResponse) {
      handleDetachTag();
    }
  }, [detachTagName]);

  const handleLoadItems = () => {
    if (physicalDeviceTagsError) {
      physicalDeviceTagsRefetch();
    }
  };

  const handleAttachTag = () => {
    fetchAttachTags('PUT')
      .then(() => {
        physicalDeviceTagsRefetch();
      })
      .catch((err: string) => {
        console.error(err);
      });
  };

  const handleDetachTag = () => {
    fetchDetachTags('DELETE')
      .then(() => {
        physicalDeviceTagsRefetch();
      })
      .catch((err: string) => {
        console.error(err);
      });
  };

  return (
    <>
      <ColumnLayout columns={2} variant="text-grid">
        <SpaceBetween size="l" key="attached-tags">
          <FormField errorText={attachTagsError} label="Attach a new tag">
            <Select
              selectedOption={selectedTag}
              onChange={({ detail }) => setSelectedTag(detail.selectedOption)}
              options={allTags}
              filteringType="auto"
              placeholder="Choose an option"
              statusType={physicalDeviceTagsLoading ? 'loading' : 'finished'}
              errorText="Error fetching tags."
              recoveryText="Retry"
              onLoadItems={handleLoadItems}
              disabled={!physicalDeviceTagsLoading && allTags === undefined}
              empty="No tags"
            />
          </FormField>
          <Button
            variant="primary"
            disabled={allTags === undefined}
            loading={attachTagsLoading}
            onClick={handleAttachTag}
          >
            Submit
          </Button>
        </SpaceBetween>
        <SpaceBetween size="l" key="action-tags">
          <TextContent>
            <Box variant="awsui-key-label">Attached Tags</Box>
            <p>
              <small>Click on X to detach tag</small>
            </p>

            {physicalDeviceTagsLoading && <Spinner />}
            {!physicalDeviceTagsLoading && (
              <TokenGroup
                onDismiss={({ detail: { itemIndex } }) => {
                  setAttachedTags([...attachedTags.slice(0, itemIndex), ...attachedTags.slice(itemIndex + 1)]);
                  setDetachTagName(attachedTags[itemIndex]?.label);
                }}
                items={attachedTags}
              />
            )}
          </TextContent>
        </SpaceBetween>
      </ColumnLayout>
    </>
  );
};

export default Tags;
