import { useLocation, Navigate, Outlet } from 'react-router-dom';

import Spinner from 'components/spinner/Spinner';
import useAuth from 'hooks/useAuth';
import React from 'react';

type allowedRolesProps = {
  allowedRoles: number[];
};

const RequireAuth = ({ allowedGroups }: { allowedGroups: (string | undefined)[] }) => {
  const { token, loading, groups } = useAuth();
  const location = useLocation();
  allowedGroups = allowedGroups.filter((group) => group);

  if (loading && !token) {
    return <Spinner />;
  }

  if (!loading && token && (allowedGroups.includes(groups) || !allowedGroups.length)) {
    return <Outlet />;
  }

  if (!loading && token) {
    return <Navigate to="/unauthorized" />;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default RequireAuth;
