import { useQuery } from "@tanstack/react-query"
import { AxiosInstance } from "axios"
import { Auth } from "aws-amplify"

type UseFetchProps<T> = {
  key: string;
  url: string;
  axiosInstance: AxiosInstance;
  enabled?: boolean;
  placeholderData?: T;
}

const fetcherFunction = async (axiosInstance: AxiosInstance, url: string) => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken()

  const result = await axiosInstance.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })

  return result.data
}

const useFetchWithReactQuery = <T = any>({ key, url, axiosInstance, enabled = true, placeholderData }: UseFetchProps<T>) => {
  const { data, error, isLoading, isFetching, status, refetch } = useQuery({
    queryKey: [key],
    queryFn: () => fetcherFunction(axiosInstance, url),
    enabled,
    placeholderData,
  })

  return { data: data as T, error, isLoading, isFetching, status, refetch }
}

export default useFetchWithReactQuery;