import { SpaceBetween, Button, Header, FormField, Input, Form } from '@cloudscape-design/components';
import { catalogAPI } from 'api';
import { usePageLayoutContext } from 'components/common/layout';
import Spinner from 'components/spinner/Spinner';
import {
  API_URL_PATH_LIST_APPLICATIONS,
  API_URL_PATH_LIST_SHOPPING_APPLICATIONS,
  URL_PATH_CATALOG_MANAGER,
  URL_PATH_CATALOG_SHOPPING,
  URL_PATH_CREATE_CATALOG_APPLICATION,
} from 'constants/urls';
import useFetch from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const CreateApplication = () => {
  const [id, setId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [parentCI, setParentCI] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [ownerUserName, setOwnerUserName] = useState<string>('');

  const [isEditFlow, setIsEditFlow] = useState(false);
  const [flowType, setFlowType] = useState('manager');
  const [disableFormFields, setDisableFormFields] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  let params = useParams();

  const { setBreadcrumb } = usePageLayoutContext();

  useEffect(() => {
    let breadcrumbItems = [];
    setFlowType('manager');

    if (
      (location && location.state?.action === 'edit') ||
      (location.pathname && location.pathname.indexOf('edit') > -1)
    ) {
      breadcrumbItems.push({
        text: 'Edit',
        href: '',
      });

      setIsEditFlow(true);
    } else {
      breadcrumbItems.push({
        text: 'Create',
        href: '/catalog/admin/application/create',
      });
    }

    const appUrl = flowType === 'manager' ? API_URL_PATH_LIST_APPLICATIONS : API_URL_PATH_LIST_SHOPPING_APPLICATIONS;

    setBreadcrumb([
      {
        text: 'Application',
        href: appUrl,
      },
      ...breadcrumbItems,
    ]);
  }, []);

  const {
    loading: applicationLoading,
    error: applicationError,
    fetchData: getApplication,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: flowType === 'manager' ? API_URL_PATH_LIST_APPLICATIONS : API_URL_PATH_LIST_SHOPPING_APPLICATIONS,
      param: params?.id,
    },
    { manual: true }
  );

  useEffect(() => {
    if (isEditFlow) {
      getApplication()
        .then((application) => {
          if (application?.data) {
            setDescription(application.data.description);
            setParentCI(application.data.parentCI);
            setId(application.data.id);
            setOwnerUserName(application.data.ownerUserName);
            setName(application.data.name);
          } else {
            const navUrl =
              flowType === 'manager' ? URL_PATH_CREATE_CATALOG_APPLICATION : API_URL_PATH_LIST_SHOPPING_APPLICATIONS;
            navigate(navUrl);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isEditFlow]);

  const {
    error: updateApplicationError,
    loading: updateApplicationLoading,
    status: updateApplicationStatus,
    fetchData: updateApp,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'PATCH',
      url: `${flowType === 'manager' ? API_URL_PATH_LIST_APPLICATIONS : API_URL_PATH_LIST_SHOPPING_APPLICATIONS}/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        parentCI,
        description,
        ownerUserName,
        name,
      },
    },
    { manual: true }
  );

  const { error, loading, status, fetchData } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'POST',
      url: API_URL_PATH_LIST_APPLICATIONS,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        name,
        parentCI,
        description,
        ownerUserName,
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (status === 201 || updateApplicationStatus === 200) {
      let stateObj = {};

      if (isEditFlow) {
        stateObj = {
          action: 'update-application',
          status: 'success',
          message: `Successfully updated application ${id}`,
          tab: 'applications',
        };
      } else {
        stateObj = {
          action: 'create-application',
          status: 'success',
          message: `Successfully created application ${id}`,
          tab: 'applications',
        };
      }

      const navUrl = flowType === 'manager' ? URL_PATH_CATALOG_MANAGER : URL_PATH_CATALOG_SHOPPING;
      navigate(navUrl, {
        state: stateObj,
      });
    } else if (status > 201 || updateApplicationStatus > 201) {
      setDisableFormFields(false);
    }
  }, [status, updateApplicationStatus]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableFormFields(true);

    if (isEditFlow) {
      await updateApp();
    } else {
      await fetchData();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link" onClick={() => navigate(URL_PATH_CATALOG_MANAGER, {})}>
              Cancel
            </Button>
            <Button variant="primary" loading={loading || updateApplicationLoading}>
              Submit
            </Button>
          </SpaceBetween>
        }
        header={
          <Header variant="h2" description="">
            {isEditFlow ? 'Update' : 'Create'} a Application
          </Header>
        }
        errorText={error || applicationError || updateApplicationError}
      >
        <SpaceBetween direction="vertical" size="l">
          {applicationLoading ? (
            <Spinner />
          ) : (
            <>
              <FormField label="Name">
                <Input
                  disabled={disableFormFields}
                  value={name}
                  onChange={(event) => setName(event.detail.value)}
                />
              </FormField>
              <FormField label="Description">
                <Input
                  disabled={disableFormFields}
                  value={description}
                  onChange={(event) => setDescription(event.detail.value)}
                />
              </FormField>
              <FormField label="Parent CI">
                <Input
                  disabled={disableFormFields}
                  value={parentCI}
                  onChange={(event) => setParentCI(event.detail.value)}
                />
              </FormField>
              <FormField label="Owner User Name">
                <Input
                  disabled={disableFormFields}
                  value={ownerUserName}
                  onChange={(event) => setOwnerUserName(event.detail.value)}
                />
              </FormField>
            </>
          )}
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default CreateApplication;
