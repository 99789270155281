import { Button } from '@cloudscape-design/components';
import { useCatalogShoppingContext } from 'pages/catalog/CatalogShopping/CatalogShoppingPage';
import CreateDeploymentShop from './CreateDeployment';
import TemplateCards from './TemplateCards';
import TemplateDetails from './TemplateDetails';

export const NextButton = ({ handleClick, text }: { handleClick: () => void; text: string }) => {
  return (
    <Button variant="primary" formAction="none" onClick={handleClick}>
      {text}
    </Button>
  );
};

const CatalogShopping = () => {
  const { stepName } = useCatalogShoppingContext();
  return stepName === 'choose' ? (
    <TemplateCards />
  ) : (
    <CreateDeploymentShop />
  );
};

export default CatalogShopping;
