import TopNavigation from '@cloudscape-design/components/top-navigation';
import './style.scss';
import useAuth from 'hooks/useAuth';
import { Box } from '@cloudscape-design/components';

const TopNav = () => {
  const { user, groups, logout } = useAuth();

  const handleDropdownClick = (event: any) => {
    event.preventDefault();

    if (event.detail.id === 'signout') {
      logout();
    }
  };

  return (
    <TopNavigation
      identity={{
        href: '/',
        logo: {
          src: 'https://www.boehringer-ingelheim.com/sites/default/files/2021-10/logo-primary-contrast.svg',
          alt: 'Home',
        },
      }}
      search={
        <div style={{ textAlign: 'center' }}>
          <Box variant="strong">PCM {window['runtimeConfig'].envTitle}</Box>
        </div>
      }
      utilities={[
        {
          type: 'menu-dropdown',
          text: user,
          description: `Groups: ${groups?.toUpperCase()}`,
          iconName: 'user-profile',
          items: [
            {
              id: 'signout',
              text: 'Sign out',
            },
          ],
          onItemClick: handleDropdownClick,
        },
      ]}
      i18nStrings={{
        searchIconAriaLabel: 'Search',
        searchDismissIconAriaLabel: 'Close search',
        overflowMenuTriggerText: 'More',
        overflowMenuTitleText: 'All',
        overflowMenuBackIconAriaLabel: 'Back',
        overflowMenuDismissIconAriaLabel: 'Close menu',
      }}
    />
  );
};

export default TopNav;
