import { ColumnLayout, Box, SpaceBetween } from '@cloudscape-design/components';
import { useDeviceTypesContext } from 'pages/device-type-manager/ListDeviceTypesPage';

const DeviceTypeDetails = () => {
  const { selectedDeviceType } = useDeviceTypesContext();
  return (
    <ColumnLayout columns={2} variant="text-grid">
      <SpaceBetween size="l">
        <div>
          <Box variant="awsui-key-label">Friendly Name</Box>
          <div>{selectedDeviceType![0]?.attributes?.friendlyName}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Description</Box>
          <div>{selectedDeviceType![0]?.description || '-'}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Status</Box>
          <div>{selectedDeviceType![0]?.attributes?.state}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Relation</Box>
          <div>{selectedDeviceType![0]?.relation}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Protocol</Box>
          <div>{selectedDeviceType![0]?.attributes.protocol}</div>
        </div>
      </SpaceBetween>
      <SpaceBetween size="l">
        <div>
          <Box variant="awsui-key-label">Manufacturer</Box>
          <div>{selectedDeviceType![0]?.attributes?.manufacturer}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Group Path</Box>
          <div>{selectedDeviceType![0]?.groupPath}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Parent Path</Box>
          <div>{selectedDeviceType![0]?.parentPath}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Purpose</Box>
          <div>{selectedDeviceType![0]?.attributes?.purpose}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">ServiceNOW Id</Box>
          <div>{selectedDeviceType![0]?.attributes?.snowId}</div>
        </div>
      </SpaceBetween>
    </ColumnLayout>
  );
};

export default DeviceTypeDetails;
