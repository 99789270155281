export const COLUMN_DEFINITIONS = [
  {
    id: 'name',
    header: 'Name',
    cell: (item: any) => item?.attributes.friendlyName,
    sortingField: 'name',
  },
  {
    id: 'id',
    header: 'ID',
    cell: (item: any) => item?.name,
    sortingField: 'id',
  },
];
