import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { extractFilteringAttributes } from 'utils';
import { Box, ColumnLayout } from '@cloudscape-design/components';
import { catalogAPI, platformCoreAPI } from 'api';
import CatalogDetailsTabs from 'components/catalog-manager/CatalogDetailsTabs';
import CatalogMainTabs from 'components/catalog-manager/CatalogMainTabs';
import {
  API_URL_PATH_LIST_ACCOUNTS,
  API_URL_PATH_LIST_APPLICATIONS,
  API_URL_PATH_LIST_DEPLOYMENTS,
  API_URL_PATH_LIST_TEMPLATES,
  API_URL_PATH_DEVICE,
  API_URL_PATH_LIST_INTEGRATION,
} from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { usePageLayoutContext } from 'components/common/layout';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  CatalogAccountProps,
  CatalogDeploymentProps,
  CatalogTemplateProps,
  CatalogApplicationProps,
  DeviceProps,
  CatalogIntegrationProps,
} from 'types/custom';
import Spinner from 'components/spinner/Spinner';

const CatalogManagerContext = createContext({
  allApplications: [] as CatalogApplicationProps[],
  allIntegrations: [] as CatalogIntegrationProps[],
  allDeployments: [] as CatalogDeploymentProps[],
  allTemplates: [] as CatalogTemplateProps[],
  allAccounts: [] as CatalogAccountProps[],
  applicationsError: '',
  integrationsError: '',
  deploymentsError: '',
  templateError: '',
  accountsError: '',
  applicationsLoading: false,
  integrationsLoading: false,
  deploymentsLoading: false,
  templateLoading: false,
  accountsLoading: false,
  selectedApplication: [] as CatalogApplicationProps[] | undefined,
  selectedIntegration: [] as CatalogIntegrationProps[] | undefined,
  selectedDeployment: [] as CatalogDeploymentProps[] | undefined,
  selectedTemplate: [] as CatalogTemplateProps[] | undefined,
  selectedAccount: [] as CatalogAccountProps[] | undefined,
  setSelectedApplication: (state: CatalogApplicationProps[]) => {},
  setSelectedIntegration: (state: CatalogIntegrationProps[]) => {},
  setSelectedDeployment: (state: CatalogDeploymentProps[]) => {},
  setSelectedTemplate: (state: CatalogTemplateProps[]) => {},
  setSelectedAccount: (state: CatalogAccountProps[]) => {},
  applicationsRefetch: () => {},
  integrationsRefetch: () => {},
  deploymentsRefetch: () => {},
  refetchTemplate: () => {},
  accountsRefetch: () => {},
  activeMainTabId: '',
  activeMainDetailsTabId: '',
  setActiveMainTabId: (state: string) => {},
  setActiveMainDetailsTabId: (state: string) => {},
});

export const useCatalogManagerContext = () => useContext(CatalogManagerContext);

const CatalogManagerPage = () => {
  const [allApplications, setAllApplications] = useState<CatalogApplicationProps[]>([]);
  const [allDeployments, setAllDeployments] = useState<CatalogDeploymentProps[]>([]);
  const [allTemplates, setAlTemplates] = useState<CatalogTemplateProps[]>([]);
  const [allAccounts, setAllAccounts] = useState<CatalogAccountProps[]>([]);
  const [allIntegrations, setAllIntegration] = useState<CatalogIntegrationProps[]>([]);
  const [selectedApplication, setSelectedApplication] = useState<CatalogApplicationProps[]>();
  const [selectedDeployment, setSelectedDeployment] = useState<CatalogDeploymentProps[]>();
  const [selectedTemplate, setSelectedTemplate] = useState<CatalogTemplateProps[]>();
  const [selectedAccount, setSelectedAccount] = useState<CatalogAccountProps[]>();
  const [activeMainTabId, setActiveMainTabId] = useState('applications');
  const [activeMainDetailsTabId, setActiveMainDetailsTabId] = useState('applicationsDetails');
  const [allDevices, setAllDevices] = useState<DeviceProps[]>([]);
  const [selectedIntegration, setSelectedIntegration] = useState<CatalogIntegrationProps[]>([]);

  const { setBreadcrumb, setNotification } = usePageLayoutContext();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setActiveMainDetailsTabId(() => activeMainTabId + 'Details');
  },[activeMainTabId])

  useEffect(() => {
    setBreadcrumb([]);
    const locationState = location?.state;
    if (locationState?.action) {
      setNotification([
        {
          type: 'success',
          content: locationState.message,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: `flash-${locationState.action}`,
        },
      ]);

      if (locationState.tab) {
        setActiveMainTabId(locationState.tab);
      }
      if (locationState.detailsTab) {
        setActiveMainDetailsTabId(locationState.detailsTab);
      }
    }

    navigate(location.pathname, {});
  }, []);

  const {
    loading: integrationsLoading,
    error: integrationsError,
    fetchData: fetchIntegration,
    refetch: integrationsRefetch,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url:`${API_URL_PATH_LIST_INTEGRATION}/application/${selectedApplication && selectedApplication.length > 0 ? selectedApplication![0].id : ''}`,
    },
    { manual: true }
  );

  const {
    response: devicesResponse,
    loading: devicesLoading,
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'GET',
      url: API_URL_PATH_DEVICE,
    },
    { manual: false }
  );

  const {
    response: applicationsResponse,
    loading: applicationsLoading,
    error: applicationsError,
    refetch: applicationsRefetch,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_APPLICATIONS,
    },
    { manual: false }
  );

  const {
    response: deploymentsResponse,
    loading: deploymentsLoading,
    error: deploymentsError,
    refetch: deploymentsRefetch,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_DEPLOYMENTS,
    },
    { manual: false }
  );

  const {
    response: templateResponse,
    loading: templateLoading,
    error: templateError,
    refetch: refetchTemplate,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_TEMPLATES,
    },
    { manual: false }
  );


  const {
    response: accountsResponse,
    loading: accountsLoading,
    error: accountsError,
    refetch: accountsRefetch,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_ACCOUNTS,
    },
    { manual: false }
  );

  useEffect(() => {
    if (devicesResponse) {
      setAllDevices(extractFilteringAttributes(devicesResponse.results) || []);
    }
  },[devicesResponse]);

  useEffect(() => {
    if (applicationsResponse) {
      setAllApplications(applicationsResponse?.items || []);
    }
  }, [applicationsResponse]);

  useEffect(() => {
    if (allApplications.length !== 0 && allDevices.length !== 0 ){
      allApplications.forEach((application: CatalogApplicationProps) => {
          let deviceNamesForApplication: string[] = allDevices
            .filter((device : DeviceProps) => device.attributes.iotApplicationId === application.id)
            .map((device : DeviceProps) => device.name)
          application.devicesName = deviceNamesForApplication;
        }) 
      setAllApplications(allApplications);
    }
  },[allDevices, allApplications])

  useEffect(() => {
    if (deploymentsResponse) {
      setAllDeployments(deploymentsResponse?.items || []);
    }
  }, [deploymentsResponse]);

  useEffect(() => {
    if (templateResponse) {
      setAlTemplates(templateResponse?.items);
    }
  }, [templateResponse]);

  useEffect(() => {
    if (accountsResponse) {
      setAllAccounts(accountsResponse?.items || []);
    }
  }, [accountsResponse]);

  useEffect(() => {
    const fetchData = async () => { 
      const response = await fetchIntegration() 
      if(response && response.status === 200){
        setAllIntegration(response.data.items || []);
      }
    };
    if(selectedApplication && selectedApplication.length > 0){
      fetchData();
    }
  },[selectedApplication])

  useEffect(() => {
    if (allIntegrations && allIntegrations.length > 0) {
      setSelectedIntegration([allIntegrations[0]]);
    }
  }, [allIntegrations]);

  return (
    <CatalogManagerContext.Provider
      value={{
        allApplications,
        allIntegrations,
        allDeployments,
        allTemplates,
        allAccounts,
        applicationsError,
        integrationsError,
        deploymentsError,
        templateError,
        accountsError,
        applicationsLoading,
        integrationsLoading,
        deploymentsLoading,
        templateLoading,
        accountsLoading,
        selectedApplication,
        selectedIntegration,
        selectedDeployment,
        selectedTemplate,
        selectedAccount,
        setSelectedApplication,
        setSelectedIntegration,
        setSelectedDeployment,
        setSelectedTemplate,
        setSelectedAccount,
        applicationsRefetch,
        integrationsRefetch,
        deploymentsRefetch,
        refetchTemplate,
        accountsRefetch,
        activeMainTabId,
        setActiveMainTabId,
        activeMainDetailsTabId,
        setActiveMainDetailsTabId,
      }}
    >
      <ColumnLayout variant="text-grid">
        {(applicationsLoading || devicesLoading) && !selectedApplication && (
          <>
            <br></br>
            <Spinner />
          </>
        )}
        {applicationsResponse && devicesResponse && <CatalogMainTabs />}
      </ColumnLayout>
      {applicationsResponse && devicesResponse && (
        <>
          <Box margin={{ top: 'xxxl' }}></Box>
          <ColumnLayout variant="text-grid">
            <CatalogDetailsTabs />
          </ColumnLayout>
        </>
      )}
      <Outlet/>
    </CatalogManagerContext.Provider>
  );
};

export default CatalogManagerPage;
