import { Box, Cards } from '@cloudscape-design/components';
import { useCatalogShoppingContext } from 'pages/catalog/CatalogShopping/CatalogShoppingPage';
import { useEffect } from 'react';
import { NextButton } from '..';

const TemplateCards = () => {
  const { allTemplates, setSelectedTemplate, selectedTemplate, templateLoading, setStepName } = useCatalogShoppingContext();

  useEffect(() => {
    setSelectedTemplate(allTemplates);
  }, []);

  const handleNextStep = () => {
    return setStepName('');
  };

  return (
    <>
      <br />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ fontSize: '20px' }}>1. Choose a Template</div>
        <NextButton handleClick={handleNextStep} text="Next" />
      </div>
      <br />
      <Cards
        className="cards-container"
        ariaLabels={{
          itemSelectionLabel: (e, n) => `select ${n.friendlyName}`,
          selectionGroupLabel: 'Item selection',
        }}
        cardDefinition={{
          header: (item: any) => <h4>{item.friendlyName}</h4>,
          sections: [
            {
              id: 'defaultBranchName',
              header: 'Default Branch Name',
              content: (item) => item.defaultBranch,
            },
            {
              id: 'repositoryUrl',
              header: 'Repository URL',
              content: (item) => item.repo,
            },
            {
              id: 'enviromentDisplayName',
              header: 'Enviroment Display Name',
              content: (item) => item.enviornmentDisplayName,
            },
            {
              id: 'requiredParameters',
              header: 'Required Parameters',
              content: (item) => item.requiredParameters.join(", ") || "",
            },
            {
              id: 'expectedOutputs',
              header: 'Expected Outputs',
              content: (item) => Object.keys(item.expectedOutputs).map((expectedOutput) => `Stack Name: ${expectedOutput} Output Value: ${item.expectedOutputs[expectedOutput]}`),
            },
            {
              id: 'description',
              header: 'Description',
              content: (item) => item.description,
            },
          ],
        }}
        cardsPerRow={[{ cards: 1 }, { minWidth: 200, cards: 3 }]}
        items={allTemplates}
        loadingText="Loading resources"
        selectionType="single"
        onSelectionChange={(event) => setSelectedTemplate(event.detail.selectedItems)}
        selectedItems={selectedTemplate}
        variant="container"
        visibleSections={['description', 'contact',]}
        trackBy="friendlyName"
        loading={templateLoading}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No resources</b>
            <Box padding={{ bottom: 's' }} variant="p" color="inherit">
              No resources to display.
            </Box>
          </Box>
        }
      />
      <br />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <NextButton handleClick={handleNextStep} text="Next" />
      </div>
      <br />
    </>
  );
};

export default TemplateCards;
