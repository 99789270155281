import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    Button,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween,
} from '@cloudscape-design/components';

import useFetch from 'hooks/useFetch';
import { URL_FULL_PATH_LIST_DEVICE } from 'constants/urls';
import Location from 'components/location';
import { platformCoreAPI } from 'api';

const InstallDevicePage = () => {
    const [name, setName] = useState<string>('');
    const [friendlyName, setFriendlyName] = useState<string>('');
    const [applicationId, setApplicationId] = useState<string>('');
    const [region, setRegion] = useState<any>('');
    const [country, setCountry] = useState<any>('');
    const [site, setSite] = useState<any>('');
    const [building, setBuilding] = useState<any>('');
    const [floor, setFloor] = useState<any>('');
    const [room, setRoom] = useState<any>('');

    const [disableFormFields, setDisableFormFields] = useState(false);
    const [deviceValidationError, setDeviceValidationError] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    let params = useParams();

    useEffect(() => {
        if (location && location.state) {
            setFriendlyName(location.state.friendlyName);
            setApplicationId(location.state.applicationId);
        }
    }, [location]);

    useEffect(() => {
        setName(params?.deviceName || '');
    }, []);

    const {
        status: installDeviceStatus,
        error: installDeviceError,
        loading: installDeviceLoading,
        fetchData: installDevice,
    } = useFetch(
        {
            axiosInstance: platformCoreAPI,
            method: 'PATCH',
            url: `/lifecycle/${name}`,
            data: {
                friendlyName,
                regionId: String(region.value),
                countryId: String(country.value),
                siteId: String(site.value),
                buildingId: String(building.value),
                floorId: String(floor.value),
                roomId: String(room.value),
                deviceState: 'inService',
                applicationId
            },
        },
        { manual: true }
    );

    useEffect(() => {
        if (installDeviceStatus === 200) {
            navigate(URL_FULL_PATH_LIST_DEVICE, {
                state: {
                    action: `install-device`,
                    status: 'success',
                    message: `Successfully installed device ${name}`,
                },
            });
        } else if (installDeviceStatus > 201) {
            setDisableFormFields(false);
        }
    }, [installDeviceStatus]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const isValid =
            friendlyName &&
            region.value &&
            country.value &&
            site.value &&
            building.value &&
            floor.value &&
            room.value;

        if (isValid) {
            setDisableFormFields(true);
            setDeviceValidationError('');
            installDevice();
        } else {
            setDeviceValidationError('Enter all required fields');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Form
                actions={
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button
                            formAction='none'
                            variant='link'
                            onClick={() =>
                                navigate(URL_FULL_PATH_LIST_DEVICE, {})
                            }
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            loading={installDeviceLoading}
                        >
                            Submit
                        </Button>
                    </SpaceBetween>
                }
                header={
                    <Header variant='h2' description=''>
                        Install a device
                    </Header>
                }
                errorText={deviceValidationError || installDeviceError}
            >
                <SpaceBetween direction='vertical' size='l'>
                    <FormField label='Device Name'>
                        <Input
                            disabled={true}
                            value={name}
                            onChange={(event) => setName(event.detail.value)}
                        />
                    </FormField>

                    <FormField label='Friendly Name'>
                        <Input
                            disabled={disableFormFields}
                            value={friendlyName}
                            onChange={(event) =>
                                setFriendlyName(event.detail.value)
                            }
                        />
                    </FormField>

                    <Location
                        region={region}
                        country={country}
                        site={site}
                        building={building}
                        floor={floor}
                        room={room}
                        setRegion={setRegion}
                        setCountry={setCountry}
                        setSite={setSite}
                        setBuilding={setBuilding}
                        setFloor={setFloor}
                        setRoom={setRoom}
                        disableFormFields={disableFormFields}
                        deepLocations={true}
                    />
                </SpaceBetween>
            </Form>
        </form>
    );
};

export default InstallDevicePage;
