import { Box, Button, FormField, Input, Modal, SpaceBetween } from '@cloudscape-design/components';

const RegisterModal = ({
  visible,
  onDiscard,
  registerAccount,
  setAccountId,
  accountId,
  error,
}: {
  visible: boolean;
  onDiscard: () => void;
  registerAccount: () => void;
  accountId: string;
  setAccountId: (state: string) => void;
  error: string;
}) => {
  return (
    <Modal
      onDismiss={onDiscard}
      visible={visible}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDiscard}>
              Cancel
            </Button>
            <Button variant="primary" onClick={registerAccount}>
              Register
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Register AWS Account"
    >
      <FormField label="Account ID" errorText={error}>
        <Input value={accountId} onChange={(event) => setAccountId(event.detail.value)} />
      </FormField>
    </Modal>
  );
};

export default RegisterModal;
