/* eslint-disable react-hooks/rules-of-hooks */
import { SpaceBetween, Box, ColumnLayout } from '@cloudscape-design/components';
import { useCatalogManagerContext } from 'pages/catalog/CatalogManager/CatalogManagerPage';
import { useCatalogShoppingContext } from 'pages/catalog/CatalogShopping/CatalogShoppingPage';

const ApplicationDetails = ({ type }: { type: string }) => {
  const context = type === 'manager' ? useCatalogManagerContext() : useCatalogShoppingContext();

  const { selectedApplication } = context;

  return (
    <ColumnLayout columns={3} variant="text-grid">
      {selectedApplication ? (
        <>
          <SpaceBetween size="l">
            <div>
              <Box variant="awsui-key-label">Name</Box>
              <div>{selectedApplication![0]?.name}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Description</Box>
              <div>{selectedApplication![0]?.description}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Id</Box>
              <div>{selectedApplication![0]?.id}</div>
            </div>
          </SpaceBetween>
          <SpaceBetween size="l">
            <div>
              <Box variant="awsui-key-label">Parent CI</Box>
              <div>{selectedApplication![0]?.parentCI}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Owner User Name</Box>
              <div>{selectedApplication![0]?.ownerUserName}</div>
            </div>
          </SpaceBetween>
          <SpaceBetween size="l">
            <div>
              <Box variant="awsui-key-label">Devices</Box>
              <div>{selectedApplication![0].devicesName && selectedApplication![0]?.devicesName.length > 0 ? selectedApplication![0]?.devicesName.join(", ") : " - "}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Created At</Box>
              <div>{selectedApplication![0]?.updatedAt}</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Updated At</Box>
              <div>{selectedApplication![0]?.updatedAt}</div>
            </div>
          </SpaceBetween>
        </>
      ) : (
        <i>No data to display</i>
      )}
    </ColumnLayout>
  );
};

export default ApplicationDetails;
