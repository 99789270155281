/* eslint-disable react-hooks/rules-of-hooks */
import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Button,
  ButtonDropdown,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
  Icon,
} from '@cloudscape-design/components';
import { catalogAPI } from 'api';
import { usePageLayoutContext } from 'components/common/layout';
import DeleteModal from 'components/delete-modal';
import { EmptyState } from 'components/empty-state/EmptyState';
import { API_URL_PATH_LIST_APPLICATIONS, API_URL_PATH_LIST_SHOPPING_APPLICATIONS } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { useCatalogManagerContext } from 'pages/catalog/CatalogManager/CatalogManagerPage';
import { useCatalogShoppingContext } from 'pages/catalog/CatalogShopping/CatalogShoppingPage';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMatchesCountText } from 'utils';
import { COLUMN_DEFINITIONS } from './table.config';

const ApplicationsTable = ({ type }: { type: string }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [flowType, setFlowType] = useState(type);

  const onDeleteDiscard = () => setShowDeleteModal(false);
  const navigate = useNavigate();

  const context = type === 'manager' ? useCatalogManagerContext() : useCatalogShoppingContext();

  const {
    allApplications,
    applicationsLoading,
    applicationsRefetch,
    selectedApplication,
    setSelectedApplication,
    setActiveMainTabId,
  } = context;

  const { setNotification } = usePageLayoutContext();

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allApplications || [],
    {
      filtering: {
        empty: (
          <EmptyState
            title="No applications"
            subtitle="No applications to display."
            action={
              <Button
                onClick={() => {
                  flowType === 'manager'
                    ? navigate('/catalog/admin/application/create', {
                      state: {
                        action: 'create',
                      },
                    })
                    : setActiveMainTabId('shopping');
                }}
              >
                Create a Application
              </Button>
            }
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: 10 },
      sorting: {},
    }
  );

  useEffect(() => {
    if (allApplications) {
      if (allApplications.length > 0) {
        setSelectedApplication([allApplications[0]]);
      }
    }
  }, [allApplications]);

  const {
    status: deleteApplicationStatus,
    fetchData: deleteApplication,
    error: deleteApplicationError,
    loading: deleteApplicationLoading,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'DELETE',
      url: `${flowType === 'manager' ? API_URL_PATH_LIST_APPLICATIONS : API_URL_PATH_LIST_SHOPPING_APPLICATIONS}/${selectedApplication?.length ? selectedApplication![0]?.id : ''
        }`,
    },
    { manual: true }
  );

  useEffect(() => {
    if (deleteApplicationStatus === 200) {
      applicationsRefetch();
      setNotification([
        {
          type: 'success',
          content: `Deleted application ${selectedApplication![0]?.name} successfully`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-delete-iot-application',
        },
      ]);

      if (allApplications.length > 0) {
        setSelectedApplication([allApplications[1]]);
      }
    } else if (deleteApplicationStatus > 200) {
      setNotification([
        {
          type: 'error',
          content: deleteApplicationError,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-delete-application-error',
        },
      ]);
    }
  }, [deleteApplicationStatus]);

  const handleButtonDropdownClick = (event: any) => {
    event.preventDefault();
    if (event.detail.id === 'app-delete') {
      setShowDeleteModal(true);
    } else if (event.detail.id === 'app-edit') {
      const editUrl = flowType === 'manager' ? '/catalog/admin/application/edit' : '/catalog/user/application/edit';
      const id = selectedApplication![0]?.id;
      navigate(`${editUrl}/${id}`, {
        state: {
          action: 'edit',
          id,
          flowType: flowType,
        },
      });
    }
  };

  const onDeleteConfirm = () => {
    deleteApplication();
    setShowDeleteModal(false);
  };

  return (
    <>
      <Table
        {...collectionProps}
        onSelectionChange={({ detail }) => {
          setSelectedApplication(detail.selectedItems);
        }}
        selectedItems={selectedApplication}
        loading={applicationsLoading || deleteApplicationLoading}
        wrapLines={true}
        stripedRows={true}
        resizableColumns={true}
        columnDefinitions={COLUMN_DEFINITIONS}
        items={items}
        loadingText="Loading applications"
        selectionType="single"
        className="table-container"
        variant="embedded"
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Find applications"
          />
        }
        header={
          <Header
            counter={
              selectedApplication && selectedApplication[0]?.id
                ? '(' + selectedApplication.length + `/${allApplications && allApplications.length})`
                : `(${allApplications && allApplications.length})`
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={applicationsRefetch} iconName="refresh" loading={applicationsLoading} />
                <Button
                  onClick={() => {
                    flowType === 'manager'
                      ? navigate('/catalog/admin/application/create', {
                        state: {
                          action: 'create',
                          flowType: flowType,
                        },
                      })
                      : setActiveMainTabId('shopping');
                  }}
                >
                  Create
                </Button>
                <ButtonDropdown
                  items={[
                    {
                      text: 'Edit',
                      id: 'app-edit',
                      disabled: allApplications?.length === 0,
                    },
                    {
                      text: 'Delete',
                      id: 'app-delete',
                      disabled: allApplications?.length === 0,
                    },
                  ]}
                  onItemClick={handleButtonDropdownClick}
                >
                  Actions
                </ButtonDropdown>
              </SpaceBetween>
            }
          >
            List of Applications
          </Header>
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
      />

      {selectedApplication && selectedApplication.length > 0 && (
        <DeleteModal
          visible={showDeleteModal}
          onDiscard={onDeleteDiscard}
          onDelete={onDeleteConfirm}
          itemName={selectedApplication![0]?.name}
          itemCount={selectedApplication?.length}
          moduleName="Application"
        />
      )}
    </>
  );
};

export default ApplicationsTable;
