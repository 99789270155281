export const COLUMN_DEFINITIONS = [
  {
    id: "msgId",
    header: "Message ID",
    cell: (item: { msgId: string }) => item.msgId,
    sortingField: "msgId",
  },
  {
    id: "devEUI",
    header: "Device EUI",
    cell: (item: { devEUI: string }) => item.devEUI,
    sortingField: "devEUI",
  },
  {
    id: "port",
    header: "Port",
    cell: (item: { port: number }) => item.port,
    sortingField: "port",
  },
  {
    id: "confirmed",
    header: "Confirmed",
    cell: (item: { confirmed: boolean }) => item.confirmed ? "Yes" : "No",
    sortingField: "confirmed",
  },
  {
    id: "data",
    header: "Data",
    cell: (item: { data: string }) => item.data || "N/A",
    sortingField: "data",
  },
];
