import { Container } from '@cloudscape-design/components';
import CreateIntegration from 'components/catalog-manager/Applications/CreateIntegration';

const UpdateIntegrationPage = () => {
  return (
    <Container >
      <CreateIntegration />
    </Container>
  );
};

export default UpdateIntegrationPage;
