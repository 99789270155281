import { Tabs } from '@cloudscape-design/components';
import PhysicalDevicesDetails from 'components/physical-devices/PhysicalDevicesDetails';
import MqttClient from 'components/physical-devices/MqttClient';
import Tags from 'components/tags';
import { usePhysicalDevicesContext } from 'pages/physical-device-manager/ListPhysicalDevicesPage';

const PhysicalDevicesTabs = () => {
  const { activeTabId, setActiveTabId } = usePhysicalDevicesContext();

  return (
    <Tabs
      activeTabId={activeTabId}
      onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
      tabs={[
        {
          label: 'Details',
          id: 'details',
          content: <PhysicalDevicesDetails />,
        },
        // {
        //     label: 'Connectivity',
        //     id: 'connectivity',
        //     content: 'connectivity',
        // },
        {
          label: 'MQTT Client',
          id: 'mqtt',
          content: <MqttClient />,
        },
        // {
        //     label: 'Monitoring',
        //     id: 'monitoring',
        //     content: 'monitoring',
        // },
        {
          label: 'Tags',
          id: 'tags',
          content: <Tags />,
        },
      ]}
      className="tabs-container"
      variant="container"
    />
  );
};

export default PhysicalDevicesTabs;
