import { useEffect, useState } from 'react';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Table,
  TextFilter,
  Header,
  SpaceBetween,
  ButtonDropdown,
  Pagination,
  Alert,
} from '@cloudscape-design/components';
import { EmptyState } from 'components/empty-state/EmptyState';

import useFetch from 'hooks/useFetch';
import { usePageLayoutContext } from 'components/common/layout';
import { COLUMN_DEFINITIONS } from './table.config';
import { useDeviceContext } from 'pages/device-manager/ListDevicesPage';
import { API_URL_PATH_BULK_DEVICE, API_URL_PATH_DEVICE } from 'constants/urls';
import DeleteModal from 'components/delete-modal';
import { getMatchesCountText } from 'utils';
import { platformCoreAPI } from 'api';
import CreateBulkDevices from '../CreateBulkDevices';
import { CreateDeviceProps } from 'types/custom';
import AssignModal from '../../assign-modal';
import AssignDeploymentModal from 'components/assign-modal/assign-deployment';

const DeviceTable = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  // isAssignDeploymentModalOpen is temporary until the assign of devices to application is fully integrated
  const [isAssignDeploymentModalOpen, setIsAssignDeploymentModalOpen] = useState(false);
  const [showCreateBulkModal, setCreateBulkModal] = useState(false);
  const [bulkDevices, setBulkDevices] = useState<CreateDeviceProps[]>([]);

  const onDeleteDiscard = () => setShowDeleteModal(false);
  const onResetDiscard = () => setShowResetModal(false);

  const navigate = useNavigate();

  const { allItems, loading, setSelectedDevice, selectedDevice, refetch, error } = useDeviceContext();

  const { setNotification } = usePageLayoutContext();

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        fields: ['friendlyName', 'name', 'description'],
        empty: (
          <EmptyState
            title="No device"
            subtitle="No device to display."
            action={
              <Button
                onClick={() =>
                  navigate('/device/create', {
                    state: {
                      action: 'create',
                    },
                  })
                }
              >
                Create a device
              </Button>
            }
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: 20 },
      sorting: {},
      // selection: {
      //     keepSelection: true,
      //     trackBy: 'name',
      //     defaultSelectedItems: [allItems.length && allItems[0]],
      // },
    }
  );

  useEffect(() => {
    if (!selectedDevice![0]?.name) {
      setSelectedDevice([allItems.length > 0 && allItems[0]?.name ? allItems[0] : []]);
    }
  }, [allItems]);

  // const [sortingDescending, setSortingDescending] = useState(false);
  // const [sortingColumn, setSortingColumn] = useState(COLUMN_DEFINITIONS[0]);

  // const handleSortingChange = (event: any) => {
  //     setSortingDescending(event.detail.isDescending);
  //     setSortingColumn(event.detail.sortingColumn);
  // };

  const {
    status: createBulkDeviceStatus,
    fetchData: createBulkDevice,
    error: createBulkDeviceError,
    loading: createBulkDeviceLoading,
    response: createBulkDeviceResponse,
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'POST',
      url: API_URL_PATH_BULK_DEVICE,
      data: bulkDevices,
    },
    { manual: true }
  );

  const {
    status: deleteDeviceStatus,
    fetchData: deleteDevice,
    error: deleteDeviceError,
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'DELETE',
      url: `${API_URL_PATH_DEVICE}/${selectedDevice?.length ? selectedDevice![0]?.name : ''}`,
    },
    { manual: true }
  );

  const {
    status: resetDeviceStatus,
    fetchData: resetDevice,
    error: resetDeviceError,
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'POST',
      url: `${API_URL_PATH_DEVICE}/reset/${selectedDevice?.length ? selectedDevice![0]?.name : ''}`,
    },
    { manual: true }
  );

  useEffect(() => {
    setCreateBulkModal(false);

    if (createBulkDeviceStatus === 201) {
      refetch();

      setNotification([
        {
          type: 'success',
          content: `Created devices in bulk successfully`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-create-device-bulk',
        },
      ]);
    } else if (createBulkDeviceStatus > 201) {
      setNotification([
        {
          type: 'error',
          content: createBulkDeviceError || 'Error',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-create-device-error',
        },
      ]);
    }
  }, [createBulkDeviceStatus, createBulkDeviceResponse]);

  useEffect(() => {
    if (deleteDeviceStatus === 200) {
      refetch();
      setNotification([
        {
          type: 'success',
          content: `Deleted device ${selectedDevice?.[0]?.name} successfully`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-delete-device-type',
        },
      ]);
    } else if (createBulkDeviceStatus > 300) {
      showErrorNotification();
    }
  }, [deleteDeviceStatus]);

  useEffect(() => {
    if (resetDeviceStatus === 201) {
      refetch();
      setNotification([
        {
          type: 'success',
          content: `Reset device ${selectedDevice![0]?.name} successfully`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-delete-device-type',
        },
      ]);
    } else if (createBulkDeviceStatus > 300) {
      showErrorNotification();
    }
  }, [resetDeviceStatus]);

  const showErrorNotification = () => {
    setNotification([
      {
        type: 'error',
        content: deleteDeviceError || resetDeviceError || 'Bad request',
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setNotification([]),
        id: 'flash-delete-device-type-error',
      },
    ]);
  };

  const handleButtonDropdownClick = async (event: any) => {
    event.preventDefault();
    if (event.detail.id === 'delete-device') {
      setShowDeleteModal(true);
    }
    if (event.detail.id === 'reset-device') {
      setShowResetModal(true);
    }
    if (event.detail.id === 'install-device') {
      navigate(
        `/device/install/${selectedDevice?.[0].name}?`,
        {
          state: {
            friendlyName: selectedDevice?.[0].attributes.friendlyName,
            applicationId: selectedDevice?.[0].attributes.iotApplicationId,
          },
        }
      );
    }
    if (event.detail.id === 'assign-device') {
      setIsAssignModalOpen(true);
    }
    // assign-device-deployment is temporary until the assign of devices to application is fully integrated
    if (event.detail.id === 'assign-device-deployment') {
      setIsAssignDeploymentModalOpen(true);
    }
  };

  const onDeleteConfirm = () => {
    setShowDeleteModal(false);
    deleteDevice();
  };

  const onResetConfirm = () => {
    setShowResetModal(false);
    resetDevice();
  };

  return (
    <>
      <Table
        {...collectionProps}
        onSelectionChange={({ detail }) => setSelectedDevice(detail.selectedItems)}
        selectedItems={selectedDevice}
        loading={loading}
        wrapLines={true}
        stripedRows={true}
        stickyHeader={true}
        columnDefinitions={COLUMN_DEFINITIONS}
        items={items}
        // onSortingChange={handleSortingChange}
        // sortingColumn={sortingColumn}
        // sortingDescending={sortingDescending}
        loadingText="Loading resources"
        selectionType="single"
        trackBy="name"
        // visibleColumns={['name', 'device-state']}
        className="table-container"
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Find resources"
          />
        }
        header={
          <Header
            counter={
              selectedDevice?.length && selectedDevice[0]?.name
                ? '(' + selectedDevice.length + `/${allItems && allItems.length})`
                : `(${allItems && allItems.length})`
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button iconName='refresh' onClick={refetch} loading={loading} />
                <Button onClick={() => setCreateBulkModal(true)}>Create from file</Button>
                <Button
                  onClick={() =>
                    navigate('/device/create', {
                      state: {
                        action: 'create',
                      },
                    })
                  }
                >
                  Create
                </Button>
                {/* Assign Deployment is temporary until the assign of devices to application is fully integrated*/}
                <ButtonDropdown
                  items={[
                    {
                      text: 'Assign',
                      disabled: selectedDevice![0]?.attributes?.deviceState === 'deleted',
                      id: 'assign-device',
                    },
                    {
                      text: 'Assign Deployment',
                      disabled: selectedDevice![0]?.attributes?.deviceState === 'deleted',
                      id: 'assign-device-deployment',
                    },
                    {
                      text: 'Install',
                      id: 'install-device',
                      disabled: selectedDevice![0]?.attributes?.deviceState !== 'inService'
                    },
                    {
                      text: 'Delete',
                      id: 'delete-device',
                      disabled: selectedDevice![0]?.attributes?.deviceState !== 'deleted',
                    },
                    {
                      text: 'Reset',
                      id: 'reset-device',
                      disabled: selectedDevice![0]?.attributes?.deviceState !== 'deleted',
                    },
                  ]}
                  onItemClick={handleButtonDropdownClick}
                >
                  Actions
                </ButtonDropdown>
              </SpaceBetween>
            }
          >
            Devices
          </Header>
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
      />

      {error && (
        <Alert type="error" header="Error">{error}</Alert>
      )}

      <DeleteModal
        visible={showDeleteModal}
        onDiscard={onDeleteDiscard}
        onDelete={onDeleteConfirm}
        itemName={selectedDevice![0]?.name}
        itemCount={selectedDevice?.length}
        moduleName="Device"
      />

      <DeleteModal
        visible={showResetModal}
        onDiscard={onResetDiscard}
        onDelete={onResetConfirm}
        itemName={selectedDevice![0]?.name}
        itemCount={selectedDevice?.length}
        moduleName="Device"
        actionName="Reset"
      />

      {selectedDevice?.length && (
        <>
          <AssignModal
            isOpen={isAssignModalOpen}
            onClose={() => setIsAssignModalOpen(false)}
            selectedDevice={selectedDevice[0]}
          />
          {/* AssignDeploymentModal is temporary until the assign of devices to application is fully integrated*/}
          <AssignDeploymentModal
            isOpen={isAssignDeploymentModalOpen}
            onClose={() => setIsAssignDeploymentModalOpen(false)}
            selectedDevice={selectedDevice[0]}
          />
        </>
      )}

      {showCreateBulkModal && (
        <CreateBulkDevices
          onDismiss={() => setCreateBulkModal(false)}
          visible={showCreateBulkModal}
          setBulkDevices={setBulkDevices}
          createBulkDevice={createBulkDevice}
          createBulkDeviceLoading={createBulkDeviceLoading}
        />
      )}
    </>
  );
};

export default DeviceTable;
