import { Container } from '@cloudscape-design/components';
import CreateIntegration from 'components/catalog-manager/Applications/CreateIntegration';

const DuplicateIntegrationPage = () => {
  return (
    <Container>
      <CreateIntegration />
    </Container>
  );
};

export default DuplicateIntegrationPage;
