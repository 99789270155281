import { Container } from '@cloudscape-design/components';
import CreateApplication from 'components/catalog-manager/Applications/CreateApplication';

const CreateApplicationPage = () => {
  return (
    <Container>
      <CreateApplication />
    </Container>
  );
};

export default CreateApplicationPage;
