import { Container } from '@cloudscape-design/components';
import CreateDeployment from 'components/catalog-manager/Deployments/CreateDeployment';

const CreateDeploymentPage = () => {
  return (
    <Container>
      <CreateDeployment />
    </Container>
  );
};

export default CreateDeploymentPage;
