export const COLUMN_DEFINITIONS = [
  {
    id: "name",
    header: "Name",
    cell: (item: { name: string }) => item.name,
    sortingField: "name",
  },
  {
    id: "description",
    header: "Description",
    cell: (item: { description: string }) => item.description,
    sortingField: "description",
  },
  {
    id: "data",
    header: "Data",
    cell: (item: { data: string }) => item.data || "N/A",
    sortingField: "data",
  },
  {
    id: "port",
    header: "Port",
    cell: (item: { port: number }) => item.port,
    sortingField: "port",
  },
  {
    id: "confirmed",
    header: "Confirmed",
    cell: (item: { confirmed: boolean }) => item.confirmed ? "Yes" : "No",
    sortingField: "confirmed",
  },
];
