import { Alert, Box, Button, Modal, Select, SelectProps, SpaceBetween, Spinner } from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { useEffect, useMemo, useState } from 'react';
import { catalogAPI, platformCoreAPI } from '../../api';
import { API_URL_PATH_LIST_DEPLOYMENTS } from '../../constants/urls';
import useFetch from '../../hooks/useFetch';
import { CatalogDeploymentProps, DeviceProps } from '../../types/custom';
import { useDeviceContext } from '../../pages/device-manager/ListDevicesPage';
import { usePageLayoutContext } from '../common/layout';

type AssignModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedDevice: DeviceProps;
};

function AssignDeploymentModal({ isOpen, onClose, selectedDevice }: AssignModalProps) {
  const [selectedApp, setSelectedApp] = useState<OptionDefinition | null>(null);
  const { refetch } = useDeviceContext();
  const { setNotification } = usePageLayoutContext();

  useEffect(() => {
    setSelectedApp(
      selectedDevice?.attributes?.iotApplicationId
        ? {
            label: selectedDevice?.attributes?.iotApplicationId,
            value: selectedDevice?.attributes?.iotApplicationId,
          }
        : null
    );
  }, [isOpen]);

  const {
    fetchData: updateDevice,
    status: updateStatus,
    error: updateError,
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'PATCH',
      url: `/lifecycle/${selectedDevice.name}`,
      data: {
        deviceState: 'inService',
        applicationId: selectedApp?.value,
        deploymentFlag: true,
      },
    },
    { manual: true }
  );

  const {
    response: apps,
    loading: appsLoading,
    error: appsError,
  } = useFetch(
    {
      axiosInstance: catalogAPI,
      method: 'GET',
      url: API_URL_PATH_LIST_DEPLOYMENTS,
    },
    { manual: false }
  );

  const allApps: SelectProps.Options = useMemo(
    () => [
      {
        label: 'Unassigned',
        value: '',
        description: 'Unassign this device',
      },
      ...(apps?.items?.map((item: CatalogDeploymentProps) => ({
        label: item.friendlyName,
        labelTag: item.iotApplicationId,
        value: item.iotApplicationId,
        description: item.description,
      })) || []),
    ],
    [apps]
  );

  useEffect(() => {
    refetch();
    if (updateStatus === 200) {
      setNotification([
        {
          type: 'success',
          content: `Success for device ${selectedDevice?.name}`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-delete-device-type',
        },
      ]);
    } else if (updateStatus > 300) {
      // Special temporal handling for this error
      const isWarning = updateError?.includes('Failed to prepare');
      setNotification([
        {
          type: isWarning ? 'warning' : 'error',
          content: `${isWarning ? 'Warning' : 'Error'} for device ${selectedDevice?.name}: ${updateError || 'Unknown error'}`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'flash-delete-device-type',
        },
      ]);
    }
  }, [updateStatus]);

  return (
    <Modal
      onDismiss={onClose}
      visible={isOpen}
      closeAriaLabel="Close modal"
      header={'Assign Device'}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                updateDevice();
                onClose();
              }}
            >
              Assign
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Select
        empty="No applications found"
        placeholder="Select an application"
        selectedOption={selectedApp}
        disabled={appsLoading}
        loadingText="Loading applications"
        errorText="Failed getting applications"
        onChange={({ detail }) => setSelectedApp(detail.selectedOption)}
        options={allApps}
      />
      {appsError && (
        <Alert type="error" header="Error">
          {appsError}
        </Alert>
      )}
    </Modal>
  );
}

export default AssignDeploymentModal;
