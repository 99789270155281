import { useFormik } from "formik";
import { platformCoreAPI } from "api";
import { Modal, Box, SpaceBetween, Button, Form, FormField, Input, Checkbox } from "@cloudscape-design/components";

import { usePageLayoutContext } from "components/common/layout";
import useMutationWithReactQuery from "hooks/useMutationWithReactQuery";
import { useDeviceTypesContext } from "pages/device-type-manager/ListDeviceTypesPage";
import { DeviceTypeResponse, Downlink } from "types/custom";

type CreateEditDownlinkModalProps = {
  selectedDownlink: any;
  isEditing: boolean;
  isOpen: boolean;
  onClose: () => void;
};

const CreateEditDownlinkModal = ({
  selectedDownlink,
  isEditing,
  isOpen,
  onClose,
}: CreateEditDownlinkModalProps) => {
  const { selectedDeviceType, refetch } = useDeviceTypesContext();
  const { setNotification } = usePageLayoutContext();

  const { values, setValues, handleSubmit, setFieldValue, isSubmitting, errors: formErrors } = useFormik<Downlink>({
    initialValues: {
      name: isEditing ? selectedDownlink?.name : '',
      description: isEditing ? selectedDownlink?.description : '',
      data: isEditing ? selectedDownlink?.data : '',
      port: isEditing ? selectedDownlink?.port : 0,
      confirmed: isEditing ? selectedDownlink?.confirmed : false,
    },
    validate: (values) => {
      const errors: Partial<Downlink> = {};
      if (!values.name) {
        errors.name = 'Name is required';
      }
      if (!values.data) {
        errors.data = 'Data is required';
      }
      if (!values.data.match(/^[A-Za-z0-9+/]+[=]{0,2}$/)) {
        errors.data = 'Data must be base64 encoded';
      }

      return errors;
    },
    enableReinitialize: true,
    async onSubmit(values) {
      if (selectedDeviceType === undefined) return;

      let updatedDownlinks = [];

      if (isEditing) {
        updatedDownlinks = (selectedDeviceType?.[0]?.downlinks || []).map((downlink) =>
          downlink.name === selectedDownlink.name ? values : downlink
        );
      } else {
        updatedDownlinks = [...(selectedDeviceType?.[0]?.downlinks || []), values];
      }

      await updateDeviceType({
        downlinks: updatedDownlinks,
      });
    },
  });

  const { mutateAsync: updateDeviceType } = useMutationWithReactQuery<DeviceTypeResponse, { downlinks: Downlink[] }>({
    url: `${platformCoreAPI.defaults.baseURL}/device-type/${selectedDeviceType?.[0].name}`,
    method: 'PATCH',
    api: platformCoreAPI,
    onSuccess: () => {
      setNotification([
        {
          type: 'success',
          content: `Successfully ${isEditing ? 'updated' : 'created'} downlink`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'downlink-success',
        },
      ]);

      setValues({
        name: '',
        description: '',
        data: '',
        port: 0,
        confirmed: false,
      });

      refetch();
      onClose();
    },
    onError: (err) => {
      setNotification([
        {
          type: 'error',
          content: err?.message || `Something went wrong while ${isEditing ? 'editing' : 'creating'} downlink`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'downlink-error',
        }]);

      setValues({
        name: '',
        description: '',
        data: '',
        port: 0,
        confirmed: false,
      });

      onClose();
    },
  });

  return (
    <Modal
      onDismiss={() => {
        setValues({
          name: '',
          description: '',
          data: '',
          port: 0,
          confirmed: false,
        });

        onClose();
      }}
      visible={isOpen}
      closeAriaLabel="Close modal"
      header={'Create a downlink'}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => {
              setValues({
                name: '',
                description: '',
                data: '',
                port: 0,
                confirmed: false,
              });

              onClose();
            }}>
              Cancel
            </Button>
            <Button variant="primary" loading={isSubmitting} onClick={() => {
              handleSubmit();
            }}>
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Form>
        <SpaceBetween direction="vertical" size="l">
          <FormField label={<span>Name</span>}>
            {formErrors.name && <span style={{ color: 'red' }}>{formErrors.name}</span>}
            <Input
              value={values.name}
              onChange={({ detail }) => setFieldValue('name', detail.value)}
            />
          </FormField>

          <FormField label={<span>Description</span>}>
            <Input
              value={values.description}
              onChange={({ detail }) => setFieldValue('description', detail.value)}
            />
          </FormField>

          <FormField label={<span>Data</span>}>
            {formErrors.data && <span style={{ color: 'red' }}>{formErrors.data}</span>}
            <Input
              value={values.data}
              onChange={({ detail }) => setFieldValue('data', detail.value)}
            />
          </FormField>

          <FormField label={<span>Port</span>}>
            <Input
              type="number"
              value={values.port.toString()}
              onChange={({ detail }) => setFieldValue('port', detail.value)}
            />
          </FormField>

          <FormField label={<span>Confirmed</span>}>
            <Checkbox
              checked={values.confirmed}
              onChange={({ detail }) => setFieldValue('confirmed', detail.checked)}
            />
          </FormField>
        </SpaceBetween>
      </Form>
    </Modal>
  );
};

export default CreateEditDownlinkModal;
