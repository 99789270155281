import { Table, Box, Button, Icon, Header, SpaceBetween } from '@cloudscape-design/components';
import useFetch from 'hooks/useFetch';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePhysicalDevicesContext } from 'pages/physical-device-manager/ListPhysicalDevicesPage';
import MqttClientDetailsModal from './MqttClientDetailsModal';
import DeleteModal from 'components/delete-modal';
import { platformCoreAPI } from 'api';

const MqttClient = () => {
  const [selectedClient, setSelectedClient] = useState<any[]>([]);
  const [showMqttClientModal, setShowMqttClientModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onDeleteDiscard = () => setShowDeleteModal(false);
  const navigate = useNavigate();

  const { selectedItem } = usePhysicalDevicesContext();

  const { response, error, loading, status, refetch } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'GET',
      // TODO: ver cómo adaptar este
      url: `/mqtt-client/physicaldevice/${selectedItem![0]?.name}?withstate=true`,
      headers: {
        accept: '*/*',
      },
    },
    { manual: false }
  );

  const {
    error: deleteMQTTClientError,
    loading: deleteMQTTClientLoading,
    status: deleteMQTTClientStatus,
    fetchData: deleteMQTTClient,
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'DELETE',
      url: `/mqtt-client/${selectedClient[0]?.deviceId}`,
      headers: {
        accept: '*/*',
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (response) {
      refetch();
    }
  }, [selectedItem]);

  useEffect(() => {
    if (deleteMQTTClientStatus === 200) {
      refetch();
    }
  }, [deleteMQTTClientStatus]);

  const handleDeleteButtonClick = () => {
    setShowDeleteModal(true);
  };

  const onDeleteConfirm = () => {
    deleteMQTTClient();
    setShowDeleteModal(false);
  };

  return (
    <>
      {showMqttClientModal && (
        <MqttClientDetailsModal setShowMqttClientModal={setShowMqttClientModal} selectedClient={selectedClient} />
      )}
      <Table
        columnDefinitions={[
          {
            id: 'name',
            header: 'Name',
            cell: (item) => (
              <p>
                <Button
                  variant="link"
                  onClick={() => {
                    setSelectedClient([item]);
                    setShowMqttClientModal(true);
                  }}
                >
                  {item?.attributes?.friendlyName}
                </Button>
              </p>
            ),
            sortingField: 'attributes.friendlyName',
          },
          {
            id: 'converterFriendlyName',
            header: 'Converter',
            cell: (item) => item?.attributes?.converterFriendlyName || '-',
          },
          {
            id: 'deviceGroupFriendlyName',
            header: 'Device Group',
            cell: (item) => item?.attributes?.deviceGroupFriendlyName || '-',
          },
          {
            id: 'status',
            header: 'Status',
            cell: (item) => item?.state || '-',
          },
        ]}
        items={response?.results}
        selectedItems={selectedClient}
        onSelectionChange={({ detail }) => setSelectedClient(detail.selectedItems)}
        loading={loading}
        loadingText="Loading resources"
        selectionType="single"
        variant="embedded"
        className="table-container"
        empty={
          <Box textAlign="center" color="inherit">
            <b>No resources</b>
            <Box padding={{ bottom: 's' }} variant="p" color="inherit">
              No applications to display.
            </Box>
            <Button
              onClick={() =>
                navigate(`/lns/create-mqtt/${selectedItem![0]?.name}`, {
                  state: {
                    selectedItem: selectedItem,
                  },
                })
              }
            >
              Create
            </Button>
          </Box>
        }
        header={
          <Header
            variant="h1"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  onClick={() =>
                    navigate(`/lns/create-mqtt/${selectedItem![0]?.name}`, {
                      state: {
                        selectedItem: selectedItem,
                      },
                    })
                  }
                >
                  Create
                </Button>
                <Button variant="normal" disabled={selectedClient.length === 0} onClick={handleDeleteButtonClick}>
                  Delete
                </Button>
              </SpaceBetween>
            }
          ></Header>
        }
      />

      <DeleteModal
        visible={showDeleteModal}
        onDiscard={onDeleteDiscard}
        onDelete={onDeleteConfirm}
        itemName={selectedItem![0]?.name}
        itemCount={selectedItem?.length}
        moduleName="MQTT Client"
      />
    </>
  );
};

export default MqttClient;
