import { Tabs } from '@cloudscape-design/components';
import { useCatalogManagerContext } from 'pages/catalog/CatalogManager/CatalogManagerPage';
import IntegrationTable from '../Applications/IntegrationTable';
import AccountDetails from './AccountDetails';
import ApplicationDetails from './ApplicationDetails';
import DeploymentDetails from './DeploymentDetails';
import TemplateDetails from './TemplateDetails';

const tabComponents: Map<string, JSX.Element> = new Map([
  ['applicationsDetails', <ApplicationDetails type="manager" />],
  ['integrationsDetails', <IntegrationTable type="manager" />],
  ['deploymentsDetails', <DeploymentDetails type="manager" />],
  ['templatesDetails', <TemplateDetails />],
  ['aws-accountsDetails', <AccountDetails />],
]);

const CatalogDetailsTabs = () => {
  const { activeMainDetailsTabId, setActiveMainDetailsTabId, activeMainTabId } = useCatalogManagerContext();

  const tableTabs: any[] = [
    {
      label: 'Details',
      id: activeMainTabId + "Details",
      content: tabComponents.get(activeMainDetailsTabId),
    },
  ];

  if (activeMainTabId === "applications"){
    tableTabs.push(
      {
        label: 'Integrations',
        id: 'integrationsDetails',
        content: tabComponents.get("integrationsDetails"),
      }
    );
  }

  return (
    <Tabs
      tabs={tableTabs}
      variant="container"
      activeTabId={activeMainDetailsTabId}
      onChange={({detail}) => setActiveMainDetailsTabId(detail.activeTabId)}
    />
  );
};

export default CatalogDetailsTabs;
