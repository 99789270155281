export const COLUMN_DEFINITIONS = [
  {
    id: 'name',
    header: 'Name',
    cell: (item: any) => item?.attributes?.friendlyName,
    sortingField: 'name',
    // width: 100,
  },
  {
    id: 'id',
    header: 'ID',
    cell: (item: any) => item?.name,
    sortingField: 'id',
    // width: 100,
  },
  {
    id: 'status',
    header: 'Status',
    cell: (item: any) => item?.attributes?.state,
    sortingField: 'status',
    // width: 60,
  },
];
