import { Alert, Box, Button, Modal, SpaceBetween } from '@cloudscape-design/components';

type DeleteModalProps = {
  visible: boolean;
  onDiscard: () => void;
  onDelete: () => void;
  itemName: string;
  itemCount?: any;
  moduleName: string;
  actionName?: string;
  isLoading?: boolean;
};

const DeleteModal = ({
  visible,
  onDiscard,
  onDelete,
  itemName,
  itemCount,
  moduleName,
  actionName = 'Delete',
  isLoading,
}: DeleteModalProps) => {
  return (
    <>
      <Modal
        onDismiss={onDiscard}
        visible={visible}
        closeAriaLabel="Close modal"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={onDiscard}>
                Cancel
              </Button>
              <Button variant="primary" loading={isLoading} onClick={onDelete}>
                {actionName}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={`${actionName} ${moduleName}`}
      >
        <Alert statusIconAriaLabel="Info">
          {actionName} {itemName} {moduleName} permanently? This action cannot be undone.
        </Alert>
      </Modal>
    </>
  );
};

export default DeleteModal;
