import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, SpaceBetween, Button, Header, FormField, Input } from '@cloudscape-design/components';

import useFetch from 'hooks/useFetch';
import { usePageLayoutContext } from 'components/common/layout';
import { PhysicalDeviceResponseProps } from 'types/custom';
import { platformCoreAPI } from 'api';

const UpdatePhysicalDevice = () => {
  const [friendlyName, setFriendlyName] = useState('');
  const [description, setDescription] = useState('');
  const [host, setHost] = useState('');
  const [credentialUserId, setCredentialUserId] = useState('');
  const [credentialUserPassword, setCredentialUserPassword] = useState('');
  const [physicaldevicetype, setPhysicaldevicetype] = useState('');
  const [disableFormFields, setDisableFormFields] = useState(true);

  const { setBreadcrumb } = usePageLayoutContext();

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    setBreadcrumb([
      {
        text: 'Physical Devices',
        href: '/lns/list',
      },
      {
        text: 'Edit',
        href: '/lns/edit',
      },
    ]);
  }, []);

  const {
    response: physicalDeviceResponse,
    loading: physicalDeviceLoading,
    status: physicalDeviceStatus,
    error: physicalDeviceError,
  }: {
    response: PhysicalDeviceResponseProps;
    loading: boolean;
    status: number;
    error: string;
  } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'GET',
      url: `/physical-device/${params?.physicaldevice}?withcredential=true`,
    },
    { manual: false }
  );

  useEffect(() => {
    if (physicalDeviceStatus) setDisableFormFields(physicalDeviceLoading);
    if (physicalDeviceStatus === 200 && physicalDeviceResponse) {
      setFriendlyName(physicalDeviceResponse.attributes.friendlyName);
      setDescription(physicalDeviceResponse.description);
      setHost(physicalDeviceResponse.attributes.host);
      setCredentialUserId(physicalDeviceResponse.attributes.credential.userId);
      setCredentialUserPassword(physicalDeviceResponse.attributes.credential.passowrd);
      setPhysicaldevicetype(physicalDeviceResponse.groups.physicaldevicetype?.[0]?.split('/')?.pop() || '');
    } else if (physicalDeviceError) {
      setDisableFormFields(false);
    }
  }, [physicalDeviceStatus]);

  const { error, loading, status, fetchData } = useFetch(
    {
      axiosInstance: platformCoreAPI,
      method: 'PATCH',
      url: `/physical-device/${params?.physicaldevice}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        friendlyName,
        description,
        host,
        credentialUserId,
        credentialUserPassword,
        physicaldevicetype,
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (status === 200) {
      navigate('/lns/list', {
        state: {
          action: 'edit-physical-device',
          status: 'success',
          message: `Successfully updated physical device ${friendlyName}`,
        },
      });
    } else if (status > 201) {
      setDisableFormFields(false);
    }
  }, [status]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisableFormFields(true);
    fetchData();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link" onClick={() => navigate('/lns/list', {})}>
              Cancel
            </Button>
            <Button variant="primary" loading={loading}>
              Submit
            </Button>
          </SpaceBetween>
        }
        header={
          <Header variant="h2" description="">
            Edit Physical Device
          </Header>
        }
        errorText={error}
      >
        <SpaceBetween direction="vertical" size="l">
          <FormField label="Friendly name">
            <Input
              disabled={disableFormFields}
              value={friendlyName}
              onChange={(event) => setFriendlyName(event.detail.value)}
            />
          </FormField>
          <FormField label="Description">
            <Input
              disabled={disableFormFields}
              value={description}
              onChange={(event) => setDescription(event.detail.value)}
            />
          </FormField>
          <FormField label="Host">
            <Input disabled={disableFormFields} value={host} onChange={(event) => setHost(event.detail.value)} />
          </FormField>
          <FormField label="Credential User Id">
            <Input
              disabled={disableFormFields}
              value={credentialUserId}
              onChange={(event) => setCredentialUserId(event.detail.value)}
            />
          </FormField>
          <FormField label="Credential User Password">
            <Input
              disabled={disableFormFields}
              value={credentialUserPassword}
              type="password"
              onChange={(event) => setCredentialUserPassword(event.detail.value)}
            />
          </FormField>
          <FormField label="Physical Device Type" description="Editing this is not currently possible">
            {/* TODO: Add support for editing this field */}
            <Input
              disabled={true}
              value={physicaldevicetype}
              onChange={(event) => setPhysicaldevicetype(event.detail.value)}
            />
          </FormField>
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default UpdatePhysicalDevice;
