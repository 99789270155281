import { useFormik } from "formik";
import { platformCoreAPI } from "api";
import { Modal, Box, SpaceBetween, Button, Form, FormField, Input, Checkbox, Select } from "@cloudscape-design/components";

import { usePageLayoutContext } from "components/common/layout";
import useMutationWithReactQuery from "hooks/useMutationWithReactQuery";
import { useDeviceTypesContext } from "pages/device-type-manager/ListDeviceTypesPage";
import { MeasurementType, DeviceTypeResponse, Measurement } from "types/custom";

type CreateEditMeasurementModalProps = {
  selectedMeasurement: any;
  isEditing: boolean;
  isOpen: boolean;
  onClose: () => void;
};

const CreateEditMeasurementModal = ({
  selectedMeasurement,
  isEditing,
  isOpen,
  onClose,
}: CreateEditMeasurementModalProps) => {
  const measurementTypeOptions = Object.values(MeasurementType).map((type) => ({ value: type, label: type }));
  const { selectedDeviceType, refetch } = useDeviceTypesContext();
  const { setNotification } = usePageLayoutContext();

  const { mutateAsync: updateDeviceType, error } = useMutationWithReactQuery<DeviceTypeResponse, { measurements: Measurement[] }>({
    url: `${platformCoreAPI.defaults.baseURL}/device-type/${selectedDeviceType?.[0].name}`,
    method: 'PATCH',
    api: platformCoreAPI,
    onSuccess: () => {
      setNotification([
        {
          type: 'success',
          content: `Successfully ${isEditing ? 'updated' : 'created'} measurement`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'measurement-success',
        },
      ]);

      refetch();
      onClose();
    },
    onError: () => {
      setNotification([
        {
          type: 'error',
          content: error?.message || `Something went wrong while ${isEditing ? 'editing' : 'creating'} measurement`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'measurement-error',
        }]);
    },
  });

  const { values, handleSubmit, setFieldValue, isSubmitting } = useFormik<Measurement>({
    initialValues: {
      name: isEditing ? selectedMeasurement?.name : '',
      type: isEditing ? selectedMeasurement?.type : '',
      unit: isEditing ? selectedMeasurement?.unit : undefined,
      favourite: isEditing ? selectedMeasurement?.favourite : false,
    },
    enableReinitialize: true,
    async onSubmit(values) {
      if (selectedDeviceType === undefined) return;

      let updatedMeasurements = [];

      if (isEditing) {
        updatedMeasurements = (selectedDeviceType?.[0]?.measurements || []).map((measurement) =>
          measurement.name === selectedMeasurement.name ? values : measurement
        );
      } else {
        updatedMeasurements = [...(selectedDeviceType?.[0]?.measurements || []), values];
      }

      await updateDeviceType({
        measurements: updatedMeasurements,
      });
    },
  });

  return (
    <Modal
      onDismiss={() => {
        onClose();
      }}
      visible={isOpen}
      closeAriaLabel="Close modal"
      header={'Create a measurement'}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => {
              onClose();
            }}>
              Cancel
            </Button>
            <Button variant="primary" loading={isSubmitting} onClick={() => {
              handleSubmit();
            }}>
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Form>
        <SpaceBetween direction="vertical" size="l">
          <FormField label={<span>Name</span>}>
            <Input
              value={values.name}
              onChange={({ detail }) => setFieldValue('name', detail.value)}
            />
          </FormField>

          <FormField label={<span>Type</span>}>
            <Select
              options={measurementTypeOptions}
              selectedOption={{ value: values.type, label: values.type }}
              onChange={({ detail }) => setFieldValue('type', detail.selectedOption.value)}
            />
          </FormField>

          {values.type === MeasurementType.Custom && <FormField label={<span>Unit</span>}>
            <Input
              value={values.unit!.toString()}
              onChange={({ detail }) => setFieldValue('unit', detail.value)}
            />
          </FormField>}

          <FormField label={<span>Favourite</span>}>
            <Checkbox
              checked={values.favourite}
              onChange={({ detail }) => setFieldValue('favourite', detail.checked)}
            />
          </FormField>
        </SpaceBetween>
      </Form>
    </Modal>
  );
};

export default CreateEditMeasurementModal;