import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { Box, Form, Button, Checkbox, FormField, Header, Input, Modal, Select, SpaceBetween } from "@cloudscape-design/components";
import { useMemo, useState } from "react";
import { useFormik } from "formik";

import { useDeviceContext } from "pages/device-manager/ListDevicesPage";
import useMutationWithReactQuery from "hooks/useMutationWithReactQuery";
import useFetchWithReactQuery from "hooks/useFetchWithReactQuery";
import { usePageLayoutContext } from "components/common/layout";
import { CustomDownlink, Downlink, StandardDownlinkMutationPayload, CustomDownlinkMutationPayload } from "types/custom";
import { platformCoreAPI } from "api";

type SendDownlinkModalProps = {
  visible: boolean;
  setIsVisible: (isOpen: boolean) => void;
  refetchTableData: () => void;
};

const SendDownlinkModal = ({ visible, setIsVisible, refetchTableData }: SendDownlinkModalProps) => {
  const [selectedDownlink, setSelectedDownlink] = useState<OptionDefinition>();
  const { selectedDevice } = useDeviceContext();
  const { setNotification } = usePageLayoutContext();

  const { data } = useFetchWithReactQuery<{ downlinks: Downlink[] }>({
    key: `device-type-${selectedDevice?.[0].attributes.deviceTypeId}`,
    axiosInstance: platformCoreAPI,
    url: `/device-type/${selectedDevice?.[0].attributes.deviceTypeId}`,
    enabled: !!selectedDevice?.[0].attributes.deviceTypeId,
  });

  const { mutate: sendDownlinkMutate, error, isPending } = useMutationWithReactQuery<any, StandardDownlinkMutationPayload | CustomDownlinkMutationPayload>({
    api: platformCoreAPI,
    method: 'POST',
    url: `/device/downlink/${selectedDownlink?.value === "custom" ? "custom" : "standard"}/${selectedDevice?.[0].name}`,
    onSuccess: () => {
      setNotification([
        {
          type: 'success',
          content: `Successfully added downlink.`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'downlink-add',
        },
      ]);
      setIsVisible(false);
      refetchTableData();
    },
    onError: () => {
      setNotification([
        {
          type: 'error',
          content: error?.message || `Something went wrong while adding downlink.`,
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => setNotification([]),
          id: 'downlink-add-error',
        }
      ]);
      setIsVisible(false);
    },
  });

  const { values, setValues, setFieldValue, handleSubmit, errors } = useFormik<CustomDownlink>({
    initialValues: {
      customData: '',
      port: 0,
      confirmed: false,
    },
    validate: (values) => {
      const errors: Partial<CustomDownlink> = {};
      if (!values.customData) {
        errors.customData = 'Data is required';
      }
      if (!values.customData.match(/^[A-Za-z0-9+/]+[=]{0,2}$/)) {
        errors.customData = 'Data must be base64 encoded';
      }

      return errors;
    },
    async onSubmit(values) {
      sendDownlinkMutate(values as CustomDownlinkMutationPayload);

      setValues({
        customData: '',
        port: 0,
        confirmed: false,
      });
    },
  });

  const downlinkOptions: OptionDefinition[] = useMemo(() => {
    if (!data) return [];

    const options = (data.downlinks || []).map((downlink) => ({
      label: downlink.name,
      value: downlink.name,
      description: `${downlink.description} on port ${downlink.port}`,
    }));

    options.push({
      label: 'Custom Downlink',
      value: 'custom',
      description: 'Send a custom downlink to the device',
    });

    return options;
  }, [data]);

  const onCancel = () => {
    setSelectedDownlink(undefined);
    setIsVisible(false);

    setValues({
      customData: '',
      port: 0,
      confirmed: false,
    });
  };

  const sendSelectedDownlink = () => {
    if (!selectedDownlink || !selectedDownlink.value) return;

    if (selectedDownlink.value === "custom") {
      handleSubmit();
      return;
    }

    sendDownlinkMutate({ downlinkName: selectedDownlink.value } as StandardDownlinkMutationPayload);
  };

  return (
    <Modal
      visible={visible}
      onDismiss={onCancel}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              loading={isPending}
              disabled={!selectedDownlink}
              onClick={sendSelectedDownlink}
            >
              Send Downlink
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={
        <Header>
          Available downlinks for {selectedDevice?.[0].name}
        </Header>
      }>
      <Select
        empty="No downlinks found"
        placeholder="Select a downlink"
        selectedOption={selectedDownlink || null}
        loadingText="Loading downlinks"
        errorText={`Failed to get downlinks for ${selectedDevice?.[0].name}`}
        onChange={({ detail }) => setSelectedDownlink(detail.selectedOption)}
        options={downlinkOptions}
      />

      {selectedDownlink?.value === "custom" &&
        <Box margin={{ top: "l" }}>
          <Form>
            <SpaceBetween direction="vertical" size="l">
              <FormField label={<span>Custom Data</span>}>
                {errors.customData && <span style={{ color: 'red' }}>{errors.customData}</span>}
                <Input
                  value={values.customData || ''}
                  onChange={({ detail }) => setFieldValue('customData', detail.value)}
                />
              </FormField>

              <FormField label={<span>Port</span>}>
                <Input
                  type="number"
                  value={values.port.toString()}
                  onChange={({ detail }) => setFieldValue('port', detail.value)}
                />
              </FormField>

              <FormField label={<span>Confirmed</span>}>
                <Checkbox
                  checked={values.confirmed || false}
                  onChange={({ detail }) => setFieldValue('confirmed', detail.checked)}
                />
              </FormField>
            </SpaceBetween>
          </Form>
        </Box>
      }
    </Modal>
  );
};

export default SendDownlinkModal;