export const COLUMN_DEFINITIONS = [
  {
    id: 'name',
    header: 'Name',
    cell: (item: any) => item?.name,
    sortingField: 'name',
  },
  {
    id: 'parentCI',
    header: 'Parent CI',
    cell: (item: any) => item?.parentCI,
    sortingField: 'parentCI',
  },
  {
    id: 'ownerUserName',
    header: 'Owner User Name',
    cell: (item: any) => item?.ownerUserName,
    sortingField: 'name',
  },
];
